<template>
    <div class="details">
        <div class="main-content">
            <div class="support-title contactus-title">
                FAQ cannot help you? Get in touch with us...
            </div>
            <div class="support-body form-body">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm contact-form" :inline="true">
                    <div class="">
                        <el-form-item prop="name" class="-col-4">
                            <el-input v-model="ruleForm.name" placeholder="Name" :validate-event="false"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="email" class="-col-4">
                            <el-input v-model="ruleForm.email" placeholder="Email" :validate-event="false"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="date" class="-col-4">
                            <el-input type="text" placeholder="Order ID (Optional)" v-model="ruleForm.date"
                                style="width: 100%;"></el-input>
                        </el-form-item>
                    </div>
                    <div class="">
                        <el-form-item prop="subject" class="-col-8">
                            <el-input v-model="ruleForm.subject" placeholder="Subject" :validate-event="false"></el-input>
                        </el-form-item>
                        <el-form-item label="" prop="package" class="-col-4">
                            <el-input v-model="ruleForm.package" placeholder="The data package you prefer(Optional)"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item label="" prop="details" class="-col-12">
                        <el-input type="textarea" v-model="ruleForm.details" placeholder="Details of your request" :validate-event="false"></el-input>
                    </el-form-item>
                    <el-form-item class="el-form-submit -col-12">
                        <el-button class="form-submit-btn" :disabled="isActive" :class="isActive ? '':'disabled'" type="primary" @click="submitForm('ruleForm')">Send</el-button>
                        <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import { touchUs } from '@/request/api'
export default {
    name: 'terms',
    data() {
        return {
            ruleForm: {
                name: '',
                email: '',
                date: '',
                subject: '',
                package: '',
                details: ''
            },
            rules: {
                name: [
                    { required: true, message: 'please enter your name', trigger: ['blur','change'] }
                ],
                email: [
                    { required: true, message: 'please enter your email', trigger: ['blur','change'] }
                ],
                subject: [
                    { required: true, message: 'please enter your subject', trigger: ['blur','change'] }
                ],
                details: [
                    { required: true, message: 'please enter your details of your request', trigger: ['blur','change'] }
                ]
            }
        }
    },
    computed: {
        isActive(){
            let flag = false;
            flag = !this.ruleForm.name || !this.ruleForm.email || !this.ruleForm.subject || !this.ruleForm.details
            return flag
        }
    },
    methods: {
        async submitForm(formName) {
            var that = this;
            let myheaders = {
                headers: { pc: true, "content-type": "multipart/form-data" },
            };
            let res = await touchUs(this.ruleForm, myheaders);
            if(res.code == 1){
                this.$message({
                    type:'success',
                    message:'send success!'
                })
                that.resetForm('ruleForm');
            }else {
                that.$message.error(res.msg);
            }
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="less" scoped>

</style>
