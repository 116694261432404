<template>
  <div class="main-content works">
      <h4 class="textTitle">Getting Started is Easy</h4>
     <div class="row col-1 md-3 easy">
       <div class="col">
         <div class="imgBox"><img src="@/assets/image/works/s1.png" alt=""></div>
         <h6>Select and purchase</h6>
         <p>Select and purchase Packages for over 110 countries Choose the country and plan that best fit for you</p>
       </div>
       <div class="col">
         <div class="imgBox"><img src="@/assets/image/works/s2.png" alt=""></div>
         <h6>Scan QRcode</h6>
         <p>Add the plan to your device eSIMs are easy and quick to install and activate on a supported device</p>
       </div>
       <div class="col">
         <div class="imgBox"><img src="@/assets/image/works/s3.png" alt=""></div>
         <h6>Stay Connected</h6>
         <p>Once you arrive to your destination, you are all set and enjoy your high-speed internet</p>
       </div>
     </div>

    <div class="video-box">
      <p class="des">Below you can find the step-by-step instructions, but you may also find helpful our video guide</p>

      <div class="iframeBox">
        <iframe src="https://www.youtube.com/embed/QHiXVs7d7iI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>

    </div>


    <div class="esim-wrap" ref="herf">
      <div class="header">
        <div class="button1" :class="{'active':tabIndex === 1}" @click="tabIndex = 1;ios = true;">eSIM installation for IOS</div>
        <div class="button1" :class="{'active':tabIndex === 2}" @click="tabIndex =2;ios = false;">eSIM installation for Android</div>
      </div>

      <p class="des">WiFi connection is required for the installation.<span class="pc"></span>
        One eSIM can only be activated on one device.</p>

      <div class="btn-ico">
        <div class="_btn">
          <h4>
            <span>Before Going Abroad</span>
            <small>Installation</small>
          </h4>
          <i class="ico-1"></i>
        </div>
      </div>

      <div class="new-box" v-if="ios">
        <div class="row md-3">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">Use Camera to scan QRcode from eSImart</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">Tap Continue to Activate eSIM at your phone</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios2.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">Add cellular plan and label it</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios3.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">Choose ‘Primary’ for Default Line</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios4.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">Choose ‘Primary’ for iMessage & FaceTime</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios5.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">6</span>
              <span class="_name">Choose ‘Primary’ for Cellular Data</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios6.jpg" alt="">
            </div>
          </div>
          <div class="col col-x">
            <div class="btn-ico btn-ico-wrap">
              <div class="_btn">
                <h4>
                  <span>Arrive at Destination</span>
                </h4>
                <i class="ico-1 ico-2"></i>
              </div>
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">7</span>
              <span class="_name">Switch to eSIM
turn on 'Data Roaming'
turn off the ‘Primary’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios7.jpg" alt="">
            </div>
          </div>
        </div>

        <div class="btn-title-wrap">
          <h4>Manual installation</h4>
        </div>


        <div class="row md-3 title-wrap-box">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">Open the “Settings” and click “Cellular”</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.1.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">Click “Add eSIM”</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.2.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">Click “Use QR Code”</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.3.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">Click the ”Enter Details Manually”</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.4.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">Enter your “SM-DP+ Address” and “Activation code” by copying and pasting.</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/ios1.5.jpg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="new-box" v-if="!ios">
        <div class="row md-3">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">Open the ‘Network’ and click cNetwork & Internet’ or ‘Connections’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">Click the  ‘+’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android2.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">Click “Download a SIM instead?</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android3.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">Scan QR code</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android4.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
              <span class="_name">Click 'Next' to download the eSIM; it will take some time</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android5.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">6</span>
              <span class="_name">Installation completion</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android6.jpg" alt="">
            </div>
          </div>
          <div class="col " style="align-self: center">
            <div class="btn-ico btn-ico-wrap">
              <div class="_btn">
                <h4>
                  <span>Arrive at Destination</span>
                </h4>
                <i class="ico-1 ico-2"></i>
              </div>
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">7</span>
              <span class="_name">'Settings' -'Network & Internet' - 'eSIM' to turn on the eSIMs  and Roaming</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android7.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">8</span>
              <span class="_name">Turn off the physical SIM</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android8.jpg" alt="">
            </div>
          </div>
        </div>

        <div class="btn-title-wrap">
          <h4>Manual installation</h4>
        </div>


        <div class="row md-3 title-wrap-box">
          <div class="col">
            <h4 class="_title">
              <span class="_num">1</span>
              <span class="_name">Open the ‘Network’ and click cNetwork & Internet’ or ‘Connections’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.1.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">2</span>
              <span class="_name">Click the  ‘+’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.2.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">3</span>
              <span class="_name">Click “Download a SIM instead?</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.3.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">4</span>
              <span class="_name">Click ‘Need help?’</span>
            </h4>
            <div class="_imgBox">
              <img src="@/assets/image/works/android1.4.jpg" alt="">
            </div>
          </div>
          <div class="col">
            <h4 class="_title">
              <span class="_num">5</span>
            </h4>
            <div class="_imgBox">
              Click 'Enter it manually', then copy and paste the Activation Code received from eSImart, and proceed with the installation process.
            </div>
          </div>
        </div>
      </div>

      <div class="box" v-if="false">
        <div class="row md-3">
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e1.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">1</div>
              <div class="_text">
                Use Camera to scan
                QRcode from eSImart
              </div>
            </div>
            <div class="txtBox">
              <p>Use your laptop or another phone</p>
              <p>to displaythe eSIM QRcode</p>
              <p>that you obtain from eSImart.</p>
              <p>Then use your iphone to scan this QRcode</p>
              <p><a href="#">Please check if your phone supports eSIM</a></p>
            </div>
          </div>
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e2.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">2</div>
              <div class="_text">Download eSIM and
                activate it at your phone</div>
            </div>
            <div class="txtBox">
              <p>When the Cellular Plan Detected</p>
              <p>notification appears, tap it.</p>
              <p>Tap Continue, at the bottom of the screen.</p>
            </div>
          </div>
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e3.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">3</div>
              <div class="_text">
                Add cellular plan
                and label it
              </div>
            </div>
            <div class="txtBox">
              <p>Label this Cellular plan, you will use it</p>
              <p>as your main data plan at destination</p>
            </div>
          </div>
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e4.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">4</div>
              <div class="_text">
                Use Camera to scan
                QRcode from eSImart
              </div>
            </div>
            <div class="txtBox">
              <p>Use your laptop or another phone</p>
              <p>to displaythe eSIM QRcode</p>
              <p>that you obtain from eSImart.</p>
              <p>Then use your iphone to scan this QRcode</p>
            </div>
          </div>
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e5.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">5</div>
              <div class="_text">
                Download eSIM and
                activate it at your phone
              </div>
            </div>
            <div class="txtBox">
              <p>When the Cellular Plan Detected</p>
              <p>notification appears, tap it.</p>
              <p>Tap Continue, at the bottom of the screen.</p>
            </div>
          </div>
          <div class="col">
            <div class="imgBox">
              <img src="@/assets/image/works/e6.png" alt="">
            </div>
            <div class="titleBox">
              <div class="_num">6</div>
              <div class="_text">
                Add cellular plan
                and label it
              </div>
            </div>
            <div class="txtBox">
              <p>Downloading eSIM with stable WiFi is</p>
              <p>necessary. Depending on the quaility</p>
            </div>
          </div>

        </div>



      </div>


    </div>


  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      tabIndex:1,
      searchTwo:'',
      ios1:[
        {
          id: 1,
          name: "Use Camera to scan QRcode from eSImart",
          img: require("@/assets/image/works/ios1.jpg")
        },
        {
          id: 2,
          name: "Tap Continue to Activate eSIM at your phone",
          img: require("@/assets/image/works/ios2.jpg")
        },
        {
          id: 3,
          name: "Add cellular plan and label it",
          img: require("@/assets/image/works/ios3.jpg")
        },
        {
          id: 4,
          name: "Choose ‘Primary’ for Default Line",
          img: require("@/assets/image/works/ios4.jpg")
        },
        {
          id: 5,
          name: "Choose ‘Primary’ for iMessage & FaceTime",
          img: require("@/assets/image/works/ios5.jpg")
        },
        {
          id: 6,
          name: "Choose ‘Primary’ for Cellular Data",
          img: require("@/assets/image/works/ios6.jpg")
        },
      ],
      // 产品列表
      dataList: [

      ],
      ios:true
    };
  },
  created() {

  },
  mounted() {
    if (this.$route.query.name && this.$route.query.name == 'herf') {
      const el = this.$refs.herf
      this.$nextTick(() => {
        if (el) {
          el.scrollIntoView({behavior: 'smooth', block: 'start'})
        }

      })
    }
  },
  methods: {

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
p{color: #ffffff;}
</style>
