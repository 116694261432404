<template>
  <div class="personal main-content" :class="open ? 'open' : ''">
    <h2 class="cart-title">
      <div class="_ico"><img src="@/assets/image/personal/title.png" alt="Member center"></div><span>&nbsp;</span>
    </h2>
    <div class="personal-content">
      <div class="personal-nav">
        <div class="personal-box">
          <ul>
            <li :class="steps == 1 ? 'active' : ''" @click="tabClick(1)">Personal information</li>
            <li :class="steps == 2 ? 'active' : ''" @click="tabClick(2)">My order</li>
            <li v-if="false" :class="steps == 3 ? 'active' : ''" @click="tabClick(3)">My eSIM</li>
            <li v-if="false" :class="steps == 4 ? 'active' : ''" @click="tabClick(4)">My collection</li>
          </ul>
          <div class="signOut"><button class="btn btn-danger" @click="Logout">Log out</button></div>
        </div>
      </div>
      <div class="personal-body">
        <div class="body-box" v-if="steps == 1">
          <div class="body-title">Personal information</div>
          <div class="user-box">
            <div class="row">
              <div class="col user_img">
                <div class="user-item">
                  <label class="user-img">
                    <input type="file" class="hide" @change="headChange" />
                    <div class="_img">
<!--                      <img :src="headImg" />-->
                      <el-image :src="headImg">
                        <div slot="error" class="image-slot">
                          <img src="@/assets/image/header/headImg.png" alt="profile" >
                        </div>
                      </el-image>
                    </div>
                    <div class="_text">Change profile</div>
                  </label>
                </div>
              </div>
              <div class="col">
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">Email</div>
                    <div class="_inner"><el-input v-model="$store.state.userInfo.account" placeholder=""
                        disabled></el-input></div>
                  </div>
                </div>
                <div class="user-item" v-if="false">
                  <div class="input-box">
                    <div class="_label">Registration methods</div>
                    <div class="_inner"><el-input v-model="zucefangshi" placeholder="" disabled></el-input></div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">Change password</div>
                    <div class="_inner"><el-input v-model="password" placeholder=""></el-input></div>
                  </div>
                </div>
                <div class="user-item">
                  <div class="input-box">
                    <div class="_label">Confirm password</div>
                    <div class="_inner"><el-input v-model="confirm_pass" placeholder=""></el-input></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="user-item">
                <div class="input-box">
                  <div class="_label">Nickname</div>
                  <div class="_inner"><el-input v-model="$store.state.userInfo.nick_name" placeholder=""></el-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="user-footer">
              <button class="btn btn-primary" @click="updateuser">Confirm the modification</button>
            </div>
          </div>
        </div>
        <div class="order-box2" v-if="steps == 2">
          <div class="body-title">My order</div>
          <div class="order-nav">
            <ul>
              <li :class="state == 0 ? 'active' : ''" @click="clickState(0)">All orders</li>
              <li :class="state == 1 ? 'active' : ''" @click="clickState(1)">Continue Payment</li>
              <li :class="state == 2 ? 'active' : ''" @click="clickState(2)">To Be Shipped</li>
              <li :class="state == 3 ? 'active' : ''" @click="clickState(3)">Completed</li>
              <li :class="state == 5 ? 'active' : ''" @click="clickState(5)">Cancelled</li>
              <li :class="state == 6 ? 'active' : ''" @click="clickState(6)">Refunded</li>
            </ul>
          </div>
          <div class="order-box">
            <div class="order-head">
              <div class="row">
                <div class="col _merge">
                  <div class="row">
                    <div class="col _commodity">Product name</div>
                    <div class="col __merge">
                      <div class="row">
                        <div class="col _quantity">Quantity</div>
                        <div class="col _unitPrice">Unit price</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col _discount">Discount</div>
                <div class="col _money">Amount</div>
                <div class="col _state">Status</div>
              </div>
            </div>
            <div class="order-body">



              <div class="order-list" v-for="(item, i) in filterOrderList" :key="i">
                <div class="_head">
                  <span>Order number：{{ item.order_number }}</span>
                  <span class="_time">Ordering time：{{ item.addTime }}</span>
                  <span class="_cancel" v-if="item.status == 1" @click="CancelOrder(item.id)">Cancel order</span>
                </div>
                <div class="_body">
                  <div class="row">
                    <div class="col _merge">
                      <div class="_list" v-for="(iten, index) in item.plan_list" :key="index + 1">
                        <div class="row">
                          <div class="col _commodity">
                            <div class="_info">
                              <div class="_img"><img :src="iten.image" /></div>
                              <div class="_txt">
                                <div class="_name">{{ iten.name }}</div>
<!--                                <div class="_describe">{{ iten.sketch }}</div>-->
                                <div class="_describe">{{ iten.days + ' Days'}} {{ iten.spec }}<small v-if="iten.package && iten.package.includes('By Day')">/ Day</small><span style="display: inline-block"> x {{ iten.num }}</span></div>
                              </div>
                            </div>
                          </div>
                          <div class="col __merge">
                            <div class="row">
                              <div class="col _quantity" data-name="Quantity:">
                                <div class="_txt">{{ iten.num }}</div>
                              </div>
                              <div class="col _unitPrice" data-name="Unit price:">
                                <div class="_txt">USD ${{ iten.special_amount }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="col _discount">
                      <div class="_txt">USD ${{item.discount?item.discount:0}}</div>
                    </div>
                    <div class="col _money" data-name="Amount:">
                      <div class="_txt">USD ${{ item.discount_amount }}</div>
                    </div>
                    <div class="col _state">
                      <div class="_txt"><span v-if="item.status  == 3" class="_ico" @click="opendig(item.plan_list)"></span><span
                          :style="{ background:item.status == 1?'#0B57C9':item.status == 2?'#D49C07':item.status == 3?'#219D71':item.status == 5 || item.status == 4?'#6C757D':'#F64343'  }"
                        @click="item.status == 1? goTO(item):''"  class="_btn">{{item.status == 1?'Continue payment':item.status == 2?'To Be Shipped':item.status == 3?'Completed':item.status == 4?'Cancelled':item.status == 5?'Cancelled':'Refunded'}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body-box" v-if="steps == 3">
          <div class="body-title">My eSIM</div>
          <div class="user-box"></div>
        </div>
        <div class="body-box" v-if="steps == 4">
          <div class="body-title">My collection</div>
          <div class="user-box"></div>
        </div>
      </div>
    </div>

    <el-dialog title="eSIM QR code"  :visible.sync="dialogTableVisible"  style="text-align: center" :close-on-click-modal="false">
      <div class="_title"><span style="color: red;letter-spacing: 0;padding-right: 5px">Click to view</span><router-link to="/works?name=herf">How to install eSIM</router-link></div>
      <div class="qrcodeList" v-for="(item,i) in palanList" :key="i">
        <h4>eSIM Plan: {{item.name}}</h4>
        <img :src="JSON.parse(item.qrcode_url)[0]" alt="item.name" />
<!--        <div class="qrcode" ref="qrCodeUrl" style="width: 100px !important;margin: 0 auto"></div>-->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { updateuserInformation, getOrderList, CancelOrder,userInformation,uploadHead,login,toPay } from '@/request/api'
export default {
  name: "personal",
  components: {},
  data() {
    return {
      open: false,
      search: '',
      steps: 1,// 1.個人資料 2.我的訂單 3.我的訂單 4.我的收藏
      state: 0,// 1 已下單（繼續支付）  2已付款  3已發貨   4取消中   5已取消 6已退款
      email: '',
      imgInitial: require('@/assets/image/personal/user.png'),
      headImg: JSON.parse(window.localStorage.getItem('userInfo'))?JSON.parse(window.localStorage.getItem('userInfo')).user_head_show:require('@/assets/image/header/headImg.png'),
      userinfo: JSON.parse(window.localStorage.getItem('userInfo')),
      zucefangshi: '一般注冊',
      password: '',
      confirm_pass: '',
      orderList: [],
      headImgTwo:'',
      dialogTableVisible:false,
      qrcode: '',
      palanList: []
    };
  },
  computed: {
    filterOrderList() {
      if (this.state !== 0) {
        return this.orderList.filter(v => v.status === String(this.state))
      }
      return this.orderList
    }
  },
  created() {
    this.steps = this.$route.query.type || 1
    window.addEventListener('popstate', this.GoReturn, false);
    if (this.steps == 2) {
      this.getOrderList()
    }
  },
  methods: {
    // qrCodeHandleClose() {
    //   this.dialogTableVisible = false;
    //   this.qrCode = '';
    //   this.palanList.forEach((it,i) =>{
    //     this.$refs.qrCodeUrl[i].innerHTML = ''
    //   })
    // },
    // showCode(){
    //   this.palanList.forEach((it,i) =>{
    //     this.creatQrCode(it.qrcode_url,i)
    //   })
    // },
    // creatQrCode(text,i) {
    //   this.qrcode = new QRCode(this.$refs.qrCodeUrl[i], {
    //     text: text,
    //     width: 100,
    //     height: 100,
    //     colorDark: '#000000',
    //     colorLight: '#ffffff',
    //     correctLevel: QRCode.CorrectLevel.H
    //   })
    // },
    opendig(item){
      this.palanList = item
      this.dialogTableVisible = true
    },

    async goTO(item) {
      // let data = {
      //    order_id:id
      // }
      // let res = await toPay(data);
      // if(res.code == 200){
      //   window.location = res.data.pay_url
      // }else{
      //   this.$message.warning(res.msg)
      // }
      this.$router.push({path: 'closing', query: {id: item.id}})
    },
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.title
        }
      })
    },
    // 加入购物车
    toShop(item) {
      // console.log(2222)
    },
    // 加入我喜欢
    toLove(item) {

    },
    GoReturn() {
      this.open = false
    },
    tabClick(item) {
      this.open = true
      this.steps = item;
      if (item == 2) {
        this.getOrderList()
      }
      this.$router.push({
        path: '/personal',
        query: {
          type: item
        }
      })

    },
    clickState(item) {
      this.state = item;
    },
    headChange(e) {
       this.get_object_url(e.target.files[0]);
    },
   async get_object_url(file) {
      var url = null;
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      if (window.createObjectURL != undefined) { // basic
        url = window.createObjectURL(file);
      } else if (window.URL != undefined) { // mozilla(firefox)
        url = window.URL.createObjectURL(file);
      } else if (window.webkitURL != undefined) { // webkit or chrome
        url = window.webkitURL.createObjectURL(file);
      }
      let res = await uploadHead(fileFormData)
      if(res.code == 200){
      let user =  JSON.parse(window.localStorage.getItem('userInfo'))

        this.headImg = res.data.show_url;
        this.headImgTwo = res.data.save_url;

      }else{
        this.$message({
          type:'warning',
          message:'Upload failed'
        })
      }

    },



    //登出
    Logout() {
      window.localStorage.removeItem('TOKEN_NAME')
      window.localStorage.removeItem('userInfo')
      let status = this.$store.state.loginStatus;   // 重新赋值
      this.$store.commit("changeStatus", false);
      this.$nextTick(() => {
        this.$store.commit("changeuserInfo", JSON.stringify({}));
        this.$store.commit('changgetCart')
      })
      this.$router.push('/')
    },

    //修改用戶信息
    async updateuser() {
      // console.log(this.headImgTwo);return;
      let req = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      if (this.password || this.confirm_pass) {
        if (req.test(this.password) && this.password == this.confirm_pass) {
          let data = {
            nick_name: this.$store.state.userInfo.nick_name,
            password: this.password,
            confirm_pass: this.confirm_pass,
            user_head: this.headImgTwo
          }
          let res = await updateuserInformation(data)
          if(res.code == 1){
               this.login()
          }
        } else if (!req.test(this.password)) {
          this.$message({
            type: 'warning',
            message: 'Password length is 6-20 characters of alphanumeric combination.'
          })
        } else if (this.passWord != this.confirm_pass) {
          this.$message({
            type: 'warning',
            message: 'The passwords entered twice do not match, please re-enter.'
          })
        }
      } else {
        let data = {
          nick_name: this.$store.state.userInfo.nick_name,
          user_head: this.headImgTwo
        }
        let res = await updateuserInformation(data)
        if(res.code == 1){
            this.getUserxinxi();
        }
      }

    },

    async login() {
      let data = {
        account: this.$store.state.userInfo.account,
        password: this.password
      }
      let rep = await login(data)
      if(rep.code == 1){
        window.localStorage.setItem('TOKEN_NAME', JSON.stringify(rep.data.token))
        this.password = '';
        this.confirm_pass = '';
        this.getUserxinxi()
        this.$emit('close', false)
       this.$nextTick(() =>{
        this.$store.commit("changeStatus", true);
        this.$store.commit('changgetCart')
       })
        this.$router.push({
        path: '/personal',
        query: {}
      })
      }else{
        this.$message({
          type:'warning',
          message:rep.msg
        })
      }

    },

    async getUserxinxi(){
        let res = await userInformation();
        window.localStorage.setItem('userInfo',JSON.stringify(res.data.user))
        this.$nextTick(() =>{
        this.$store.commit("changeuserInfo", JSON.stringify(res.data.user));
       })
    },

    //獲取訂單
    async getOrderList() {
      let res = await getOrderList();
      if (res.code == 1) {
        this.orderList = res.data
      } else {
        this.$message({
          type: 'warning',
          message: res.msg
        })
      }

    },

    //取消訂單
    async CancelOrder(id) {
      let data = {
        order_id: id
      }
      let res = await CancelOrder(data);
      if (res.code == 1) {
          this.getOrderList()
      } else {
        this.$message({
          type: 'warning',
          message: res.msg
        })
      }
    }

  }
}
</script>

<style lang="less" scoped>
  /deep/.el-input.is-disabled .el-input__inner{
    background-color: #2e2e2e;
    border-color: #2e2e2e;
    color: #ffffff;
  }
  ._title{
    margin-top: -20px !important;
    margin-bottom: 20px;
    a {
      color: #00a0e9 !important;
    }
  }
  .qrcodeList{
    margin-bottom: 20px;
    h4{
      margin: 0;
      margin-bottom: 10px;
    }
  }
</style>
