export const country = [
    {
        pos:'-66px 0',
        name:'Afghanistan (‫افغانستان‬‎)',
        height: '14px',
        code:'93',
        value:93,
    },
    {
        pos:'-132px 0',
        name:'Albania (Shqipëri)',
        height: '15px',
        code:'355',
        value:2,
    },
    {
        pos:'-1401px 0',
        name:'Algeria (‫الجزائر‬‎)',
        height: '14px',
        code:'213',
        value:3,
    },
    {
        pos:'-242px 0',
        name:'American Samoa',
        height: '10px',
        code:'1684',
        value:1684,
    },
    {
        pos:'-22px 0',
        name:'Andorra',
        height: '14px',
        code:'376',
        value:376,
    },
    {
        pos:'-176px 0',
        name:'Angola',
        height: '14px',
        code:'244',
        value:244,
    },
    {
        pos:'-110px 0',
        name:'Anguilla',
        height: '10px',
        code:'1264',
        value:1264,
    },
    {
        pos:'-88px 0',
        name:'Antigua and Barbuda',
        height: '14px',
        code:'1268',
        value:1268,
    },
    {
        pos:'-220px 0',
        name:'Argentina',
        height: '13px',
        code:'54',
        value:54,
    },
    {
        pos:'-154px 0',
        name:'Armenia (Հայաստան)',
        height: '10px',
        code:'374',
        value:374,
    },
    {
        pos:'-308px 0',
        name:'Aruba',
        height: '14px',
        code:'297',
        value:297,
    },
    {
        pos:'-286px 0',
        name:'Australia',
        height: '10px',
        code:'61',
        value:61,
    },
    {
        pos:'-264px 0',
        name:'Austria (Österreich)',
        height: '14px',
        code:'43',
        value:43,
    },
    {
        pos:'-352px 0',
        name:'Azerbaijan (Azərbaycan)',
        height: '10px',
        code:'994',
        value:994,
    },
    {
        pos:'-702px 0',
        name:'Bahamas',
        height: '10px',
        code:'1242',
        value:1242,
    },
    {
        pos:'-504px 0',
        name:'Bahrain (‫البحرين‬‎)',
        height: '12px',
        code:'973',
        value:973,
    },
    {
        pos:'-418px 0',
        name:'Bangladesh (বাংলাদেশ)',
        height: '12px',
        code:'880',
        value:880,
    },
    {
        pos:'-396px 0',
        name:'Barbados',
        height: '14px',
        code:'1246',
        value:1246,
    },
    {
        pos:'-790px 0',
        name:'Belarus (Беларусь)',
        height: '10px',
        code:'375',
        value: 375,
    },
    {
        pos:'-440px 0',
        name:'Belgium (België)',
        height: '15px',
        code:'32',
        value: 32,
    },
    {
        pos:'-812px 0',
        name:'Belize',
        height: '14px',
        code:'501',
        value: 0,
    },
    {
        pos:'-548px 0',
        name:'Benin (Bénin)',
        height: '14px',
        code:'229',
        value: 229,
    },
    {
        pos:'-592px 0',
        name:'Bermuda',
        height: '10px',
        code:'1441',
        value: 1441,
    },
    {
        pos:'-724px 0',
        name:'Bhutan (འབྲུག)',
        height: '14px',
        code:'975',
        value: 975,
    },
    {
        pos:'-636px 0',
        name:'Bolivia',
        height: '14px',
        code:'591',
        value: 591,
    },
    {
        pos:'-374px 0',
        name:'Bosnia and Herzegovina (Босна и Херцеговина)',
        height: '10px',
        code:'387',
        value: 387,
    },
    {
        pos:'-768px 0',
        name:'Botswana',
        height: '14px',
        code:'267',
        value: 267,
    },
    {
        pos:'-680px 0',
        name:'Brazil (Brasil)',
        height: '14px',
        code:'55',
        value: 55,
    },
    {
        pos:'-2435px 0',
        name:'British Indian Ocean Territory',
        height: '10px',
        code:'246',
        value: 246,
    },
    {
        pos:'-5368px 0',
        name:'British Virgin Islands',
        height: '10px',
        code:'1284',
        value: 1284,
    },
    {
        pos:'-614px 0',
        name:'Brunei',
        height: '10px',
        code:'673',
        value: 673,
    },
    {
        pos:'-482px 0',
        name:'Bulgaria (България)',
        height: '12px',
        code:'359',
        value: 359,
    },
    {
        pos:'-460px 0',
        name:'Burkina Faso',
        height: '14px',
        code:'226',
        value: 226,
    },
    {
        pos:'-526px 0px',
        name:'Burundi (Uburundi)',
        height: '12px',
        code:'257',
        value: 257,
    },
    {
        pos:'-2677px 0px',
        name:'Cambodia (កម្ពុជា)',
        height: '13px',
        code:'855',
        value: 855,
    },
    {
        pos:'-1027px 0px',
        name:'Cameroon (Cameroun)',
        height: '14px',
        code:'237',
        value: 237,
    },
    {
        pos:'-834px 0px',
        name:'Canada',
        height: '10px',
        code:'1',
        value: 1,
    },
    {
        pos:'-1159px 0px',
        name:'Cape Verde (Kabu Verdi)',
        height: '12px',
        code:'238',
        value: 238,
    },
    {
        pos:'-658px 0px',
        name:'Caribbean Netherlands',
        height: '14px',
        code:'599',
        value: 599,
    },
    {
        pos:'-2831px 0px',
        name:'Cayman Islands',
        height: '10px',
        code:'1345',
        value: 1345,
    },
    {
        pos:'-900px 0px',
        name:'Central African Republic (République centrafricaine)',
        height: '14px',
        code:'236',
        value: 236,
    },
    {
        pos:'-4845px 0px',
        name:'Chad (Tchad)',
        height: '14px',
        code:'235',
        value: 235,
    },
    {
        pos:'-1005px 0px',
        name:'Chile',
        height: '14px',
        code:'56',
        value: 56,
    },
    {
        pos:'-1049px 0px',
        name:'China (中国)',
        height: '14px',
        code:'86',
        value: 86,
    },
    // {
    //     pos:'-1203px 0px',
    //     name:'Christmas Island',
    //     height: '10px',
    //     code:'61',
    //     value: 61,
    // },
    // {
    //     pos:'-856px 0px',
    //     name:'Cocos (Keeling) Islands',
    //     height: '10px',
    //     code:'61',
    //     value: 61,
    // },
    {
        pos:'-1071px 0px',
        name:'Colombia',
        height: '14px',
        code:'57',
        value: 57,
    },
    {
        pos:'-2721px 0px',
        name:'Comoros (‫جزر القمر‬‎)',
        height: '12px',
        code:'269',
        value: 269,
    },
    {
        pos:'-878px 0px',
        name:'Congo (DRC) (République démocratique du Congo)',
        height: '15px',
        code:'243',
        value: 243,
    },
    {
        pos:'-922px 0px',
        name:'Congo (Republic) (Congo-Brazzaville)',
        height: '14px',
        code:'242',
        value: 242,
    },
    {
        pos:'-983px 0px',
        name:'Cook Islands',
        height: '10px',
        code:'682',
        value: 682,
    },
    {
        pos:'-1115px 0px',
        name:'Costa Rica',
        height: '12px',
        code:'506',
        value: 506,
    },
    {
        pos:'-961px 0px',
        name:'Côte d’Ivoire',
        height: '14px',
        code:'225',
        value: 225,
    },
    {
        pos:'-2237px 0px',
        name:'Croatia (Hrvatska)',
        height: '10px',
        code:'385',
        value: 385,
    },
    {
        pos:'-1137px 0px',
        name:'Cuba',
        height: '10px',
        code:'53',
        value: 53,
    },
    {
        pos:'-1181px 0px',
        name:'Curaçao',
        height: '14px',
        code:'599',
        value: 599,
    },
    {
        pos:'-1225px 0px',
        name:'Cyprus (Κύπρος)',
        height: '14px',
        code:'357',
        value: 357,
    },
    {
        pos:'-1247px 0px',
        name:'Czech Republic (Česká republika)',
        height: '14px',
        code:'420',
        value: 420,
    },
    {
        pos:'-1335px 0px',
        name:'Denmark (Danmark)',
        height: '15px',
        code:'45',
        value: 45,
    },
    {
        pos:'-1313px 0px',
        name:'Djibouti',
        height: '14px',
        code:'253',
        value: 253,
    },
    {
        pos:'-1357px 0px',
        name:'Dominica',
        height: '10px',
        code:'1767',
        value: 1767,
    },
    {
        pos:'-1379px 0px',
        name:'Dominican Republic (República Dominicana)',
        height: '13px',
        code:'1',
        value: 1,
    },
    {
        pos:'-1445px 0px',
        name:'Ecuador',
        height: '14px',
        code:'593',
        value: 593,
    },
    {
        pos:'-1489px 0px',
        name:'Egypt (‫مصر‬‎)',
        height: '14px',
        code:'20',
        value: 20,
    },
    {
        pos:'-4713px 0px',
        name:'El Salvador',
        height: '12px',
        code:'503',
        value: 503,
    },
    {
        pos:'-2017px 0px',
        name:'Equatorial Guinea (Guinea Ecuatorial)',
        height: '14px',
        code:'240',
        value: 240,
    },
    {
        pos:'-1533px 0px',
        name:'Eritrea',
        height: '10px',
        code:'291',
        value: 291,
    },
    {
        pos:'-1467px 0px',
        name:'Estonia (Eesti)',
        height: '13px',
        code:'372',
        value: 372,
    },
    {
        pos:'-1577px 0px',
        name:'Ethiopia',
        height: '10px',
        code:'251',
        value: 251,
    },
    {
        pos:'-1665px 0px',
        name:'Falkland Islands (Islas Malvinas)',
        height: '10px',
        code:'500',
        value: 500,
    },
    {
        pos:'-1709px 0px',
        name:'Faroe Islands (Føroyar)',
        height: '15px',
        code:'298',
        value: 298,
    },
    {
        pos:'-1643px 0px',
        name:'Fiji',
        height: '10px',
        code:'679',
        value: 679,
    },
    {
        pos:'-1621px 0px',
        name:'Finland (Suomi)',
        height: '12px',
        code:'358',
        value: 358,
    },
    {
        pos:'-1731px 0px',
        name:'France',
        height: '14px',
        code:'33',
        value: 33,
    },
    {
        pos:'-1841px 0px',
        name:'French Guiana (Guyane française)',
        height: '14px',
        code:'594',
        value: 594,
    },
    {
        pos:'-3943px 0px',
        name:'French Polynesia (Polynésie française)',
        height: '14px',
        code:'689',
        value: 689,
    },
    {
        pos:'-1753px 0px',
        name:'Gabon',
        height: '15px',
        code:'241',
        value: 241,
    },
    {
        pos:'-1951px 0px',
        name:'Gambia',
        height: '14px',
        code:'220',
        value: 220,
    },
    {
        pos:'-1819px 0px',
        name:'Georgia (საქართველო)',
        height: '14px',
        code:'995',
        value: 995,
    },
    {
        pos:'-1269px 0px',
        name:'Germany (Deutschland)',
        height: '12px',
        code:'49',
        value: 49,
    },
    {
        pos:'-1885px 0px',
        name:'Ghana (Gaana)',
        height: '14px',
        code:'233',
        value: 233,
    },
    {
        pos:'-1907px 0px',
        name:'Gibraltar',
        height: '10px',
        code:'350',
        value: 350,
    },
    {
        pos:'-2039px 0px',
        name:'Greece (Ελλάδα)',
        height: '14px',
        code:'30',
        value: 30,
    },
    {
        pos:'-1929px 0px',
        name:'Greenland (Kalaallit Nunaat)',
        height: '14px',
        code:'299',
        value: 299,
    },
    {
        pos:'-1797px 0px',
        name:'Grenada',
        height: '12px',
        code:'1473',
        value: 1473,
    },
    {
        pos:'-1995px 0px',
        name:'Guadeloupe',
        height: '14px',
        code:'590',
        value: 590,
    },
    {
        pos:'-2105px 0px',
        name:'Guam',
        height: '11px',
        code:'1671',
        value: 1671,
    },
    {
        pos:'-2083px 0px',
        name:'Guatemala',
        height: '13px',
        code:'502',
        value: 502,
    },
    {
        pos:'-1863px 0px',
        name:'Guernsey',
        height: '14px',
        code:'44',
        value: 44,
    },
    {
        pos:'-1973px 0px',
        name:'Guinea (Guinée)',
        height: '14px',
        code:'224',
        value: 224,
    },
    {
        pos:'-2127px 0px',
        name:'Guinea-Bissau (Guiné Bissau)',
        height: '10px',
        code:'245',
        value: 245,
    },
    {
        pos:'-2149px 0px',
        name:'Guyana',
        height: '12px',
        code:'592',
        value: 592,
    },
    {
        pos:'-2259px 0px',
        name:'Haiti',
        height: '12px',
        code:'509',
        value: 509,
    },
    {
        pos:'-2215px 0px',
        name:'Honduras',
        height: '10px',
        code:'504',
        value: 504,
    },
    {
        pos:'-2171px 0px',
        name:'Hong Kong (香港)',
        height: '14px',
        code:'852',
        value: 1852111,
    },
    {
        pos:'-2281px 0px',
        name:'Hungary (Magyarország)',
        height: '10px',
        code:'36',
        value: 36,
    },
    {
        pos:'-2501px 0px',
        name:'Iceland (Ísland)',
        height: '15px',
        code:'354',
        value: 354,
    },
    {
        pos:'-2413px 0px',
        name:'India (भारत)',
        height: '14px',
        code:'91',
        value: 91,
    },
    {
        pos:'-2325px 0px',
        name:'Indonesia',
        height: '14px',
        code:'62',
        value: 62,
    },
    {
        pos:'-2479px 0px',
        name:'Iran (‫ایران‬‎)',
        height: '12px',
        code:'98',
        value: 98,
    },
    {
        pos:'-2457px 0px',
        name:'Iraq (‫العراق‬‎)',
        height: '14px',
        code:'964',
        value: 964,
    },
    {
        pos:'-2347px 0px',
        name:'Ireland',
        height: '10px',
        code:'353',
        value: 353,
    },
    {
        pos:'-2391px 0px',
        name:'Isle of Man',
        height: '10px',
        code:'44',
        value: 44,
    },
    {
        pos:'-2369px 0px',
        name:'Israel (‫ישראל‬‎)',
        height: '15px',
        code:'972',
        value: 972,
    },
    {
        pos:'-2523px 0px',
        name:'Italy (Italia)',
        height: '14px',
        code:'39',
        value: 39,
    },
    {
        pos:'-2567px 0px',
        name:'Jamaica',
        height: '10px',
        code:'1876',
        value: 1876,
    },
    {
        pos:'-2611px 0px',
        name:'Japan (日本)',
        height: '14px',
        code:'81',
        value: 81,
    },
    {
        pos:'-2545px 0px',
        name:'Jersey',
        height: '12px',
        code:'44',
        value: 44,
    },
    {
        pos:'-2589px 0px',
        name:'Jordan (‫الأردن‬‎)',
        height: '10px',
        code:'962',
        value: 962,
    },
    {
        pos:'-2853px 0px',
        name:'Kazakhstan (Казахстан)',
        height: '10px',
        code:'7',
        value: 7,
    },
    {
        pos:'-2633px 0px',
        name:'Kenya',
        height: '14px',
        code:'254',
        value: 254,
    },
    {
        pos:'-2699px 0px',
        name:'Kiribati',
        height: '10px',
        code:'686',
        value: 686,
    },
    {
        pos:'-5500px 0px',
        name:'Kosovo',
        height: '15px',
        code:'383',
        value: 383,
    },
    {
        pos:'-2809px 0px',
        name:'Kuwait (‫الكويت‬‎)',
        height: '10px',
        code:'965',
        value: 965,
    },
    {
        pos:'-2655px 0px',
        name:'Kyrgyzstan (Кыргызстан)',
        height: '12px',
        code:'996',
        value: 996,
    },
    {
        pos:'-2875px 0px',
        name:'Laos (ລາວ)',
        height: '14px',
        code:'856',
        value: 856,
    },
    {
        pos:'-3073px 0px',
        name:'Latvia (Latvija)',
        height: '10px',
        code:'371',
        value: 371,
    },
    {
        pos:'-2897px 0px',
        name:'Lebanon (‫لبنان‬‎)',
        height: '14px',
        code:'961',
        value: 961,
    },
    {
        pos:'-3007px 0px',
        name:'Lesotho',
        height: '14px',
        code:'266',
        value: 266,
    },
    {
        pos:'-2985px 0px',
        name:'Liberia',
        height: '11px',
        code:'231',
        value: 231,
    },
    {
        pos:'-3095px 0px',
        name:'Libya (‫ليبيا‬‎)',
        height: '10px',
        code:'218',
        value: 218,
    },
    {
        pos:'-2941px 0px',
        name:'Liechtenstein',
        height: '12px',
        code:'423',
        value: 423,
    },
    {
        pos:'-3029px 0px',
        name:'Lithuania (Lietuva)',
        height: '12px',
        code:'370',
        value: 370,
    },
    {
        pos:'-3051px 0px',
        name:'Luxembourg',
        height: '12px',
        code:'352',
        value: 352,
    },
    {
        pos:'-3358px 0px',
        name:'Macau (澳門)',
        height: '14px',
        code:'853',
        value: 853,
    },
    {
        pos:'-3270px 0px',
        name:'Macedonia (FYROM) (Македонија)',
        height: '10px',
        code:'389',
        value: 389,
    },
    {
        pos:'-3226px 0px',
        name:'Madagascar (Madagasikara)',
        height: '14px',
        code:'261',
        value: 261,
    },
    {
        pos:'-3534px 0px',
        name:'Malawi',
        height: '14px',
        code:'265',
        value: 265,
    },
    {
        pos:'-3578px 0px',
        name:'Malaysia',
        height: '10px',
        code:'60',
        value: 60,
    },
    {
        pos:'-3512px 0px',
        name:'Maldives',
        height: '14px',
        code:'960',
        value: 960,
    },
    {
        pos:'-3292px 0px',
        name:'Mali',
        height: '14px',
        code:'223',
        value: 223,
    },
    {
        pos:'-3468px 0px',
        name:'Malta',
        height: '14px',
        code:'356',
        value: 356,
    },
    {
        pos:'-3248px 0px',
        name:'Marshall Islands',
        height: '11px',
        code:'692',
        value: 692,
    },
    {
        pos:'-3402px 0px',
        name:'Martinique',
        height: '14px',
        code:'596',
        value: 596,
    },
    {
        pos:'-3424px 0px',
        name:'Mauritania (‫موريتانيا‬‎)',
        height: '14px',
        code:'222',
        value: 222,
    },
    {
        pos:'-3490px 0px',
        name:'Mauritius (Moris)',
        height: '14px',
        code:'230',
        value: 230,
    },
    {
        pos:'-5544px 0px',
        name:'Mayotte',
        height: '14px',
        code:'262',
        value: 262,
    },
    {
        pos:'-3556px 0px',
        name:'Mexico (México)',
        height: '12px',
        code:'52',
        value: 52,
    },
    {
        pos:'-1687px 0px',
        name:'Micronesia',
        height: '11px',
        code:'691',
        value: 691,
    },
    {
        pos:'-3160px 0px',
        name:'Moldova (Republica Moldova)',
        height: '10px',
        code:'373',
        value: 373,
    },
    {
        pos:'-3139px 0px',
        name:'Monaco',
        height: '15px',
        code:'377',
        value: 377,
    },
    {
        pos:'-3336px 0px',
        name:'Mongolia (Монгол)',
        height: '10px',
        code:'976',
        value: 976,
    },
    {
        pos:'-3182px 0px',
        name:'Montenegro (Crna Gora)',
        height: '10px',
        code:'382',
        value: 382,
    },
    {
        pos:'-3446px 0px',
        name:'Montserrat',
        height: '10px',
        code:'1664',
        value: 1664,
    },
    {
        pos:'-3117px 0px',
        name:'Morocco (‫المغرب‬‎)',
        height: '14px',
        code:'212',
        value: 212,
    },
    {
        pos:'-3600px 0px',
        name:'Mozambique (Moçambique)',
        height: '14px',
        code:'258',
        value: 258,
    },
    {
        pos:'-3314px 0px',
        name:'Myanmar (Burma) (မြန်မာ)',
        height: '14px',
        code:'95',
        value: 95,
    },
    {
        pos:'-3622px 0px',
        name:'Namibia (Namibië)',
        height: '14px',
        code:'264',
        value: 264,
    },
    {
        pos:'-3811px 0px',
        name:'Nauru',
        height: '10px',
        code:'674',
        value: 674,
    },
    {
        pos:'-3796px 0px',
        name:'Nepal (नेपाल)',
        height: '15px',
        code:'977',
        value: 977,
    },
    {
        pos:'-3752px 0px',
        name:'Netherlands (Nederland)',
        height: '14px',
        code:'31',
        value: 31,
    },
    {
        pos:'-3644px 0px',
        name:'New Caledonia (Nouvelle-Calédonie)',
        height: '10px',
        code:'687',
        value: 687,
    },
    {
        pos:'-3855px 0px',
        name:'New Zealand',
        height: '10px',
        code:'64',
        value: 64,
    },
    {
        pos:'-3730px 0px',
        name:'Nicaragua',
        height: '12px',
        code:'505',
        value: 505,
    },
    {
        pos:'-3666px 0px',
        name:'Niger (Nijar)',
        height: '15px',
        code:'227',
        value: 227,
    },
    {
        pos:'-3708px 0px',
        name:'Nigeria',
        height: '10px',
        code:'234',
        value: 234,
    },
    {
        pos:'-3833px 0px',
        name:'Niue',
        height: '10px',
        code:'683',
        value: 683,
    },
    {
        pos:'-3686px 0px',
        name:'Norfolk Island',
        height: '10px',
        code:'672',
        value: 672,
    },
    {
        pos:'-2765px 0px',
        name:'North Korea (조선 민주주의 인민 공화국)',
        height: '10px',
        code:'850',
        value: 850,
    },
    {
        pos:'-3380px 0px',
        name:'Northern Mariana Islands',
        height: '10px',
        code:'1670',
        value: 1670,
    },
    {
        pos:'-3774px 0px',
        name:'Norway (Norge)',
        height: '15px',
        code:'47',
        value: 47,
    },
    {
        pos:'-3877px 0px',
        name:'Oman (‫عُمان‬‎)',
        height: '10px',
        code:'968',
        value: 968,
    },
    {
        pos:'-4009px 0px',
        name:'Pakistan (‫پاکستان‬‎)',
        height: '14px',
        code:'92',
        value: 92,
    },
    {
        pos:'-4163px 0px',
        name:'Palau',
        height: '13px',
        code:'680',
        value: 680,
    },
    {
        pos:'-4119px 0px',
        name:'Palestine (‫فلسطين‬‎)',
        height: '10px',
        code:'970',
        value: 970,
    },
    {
        pos:'-3899px 0px',
        name:'Panama (Panamá)',
        height: '14px',
        code:'507',
        value: 507,
    },
    {
        pos:'-3965px 0px',
        name:'Papua New Guinea',
        height: '15px',
        code:'675',
        value: 675,
    },
    {
        pos:'-4185px 0px',
        name:'Paraguay',
        height: '11px',
        code:'595',
        value: 595,
    },
    {
        pos:'-3921px 0px',
        name:'Peru (Perú)',
        height: '14px',
        code:'51',
        value: 51,
    },
    {
        pos:'-3987px 0px',
        name:'Philippines',
        height: '10px',
        code:'63',
        value: 63,
    },
    {
        pos:'-4031px 0px',
        name:'Poland (Polska)',
        height: '13px',
        code:'48',
        value: 48,
    },
    {
        pos:'-4141px 0px',
        name:'Portugal',
        height: '14px',
        code:'351',
        value: 351,
    },
    {
        pos:'-4097px 0px',
        name:'Puerto Rico',
        height: '14px',
        code:'1',
        value: 1,
    },
    {
        pos:'-4207px 0px',
        name:'Qatar (‫قطر‬‎)',
        height: '8px',
        code:'974',
        value: 974,
    },
    {
        pos:'-4229px 0px',
        name:'Réunion (La Réunion)',
        height: '14px',
        code:'262',
        value: 262,
    },
    {
        pos:'-4251px 0px',
        name:'Romania (România)',
        height: '14px',
        code:'40',
        value: 40,
    },
    {
        pos:'-4295px 0px',
        name:'Russia (Россия)',
        height: '14px',
        code:'7',
        value: 7,
    },
    {
        pos:'-4317px 0px',
        name:'Rwanda',
        height: '14px',
        code:'250',
        value: 250,
    },
    {
        pos:'-570px 0px',
        name:'Saint Barthélemy',
        height: '14px',
        code:'590',
        value: 590,
    },
    {
        pos:'-4471px 0px',
        name:'Saint Helena',
        height: '10px',
        code:'290',
        value: 290,
    },
    {
        pos:'-2743px 0px',
        name:'Saint Kitts and Nevis',
        height: '14px',
        code:'1869',
        value: 1869,
    },
    {
        pos:'-2919px 0px',
        name:'Saint Lucia',
        height: '10px',
        code:'1758',
        value: 1758,
    },
    {
        pos:'-3204px 0px',
        name:'Saint Martin (Saint-Martin (partie française))',
        height: '14px',
        code:'590',
        value: 590,
    },
    {
        pos:'-4053px 0px',
        name:'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
        height: '14px',
        code:'508',
        value: 508,
    },
    {
        pos:'-5324px 0px',
        name:'Saint Vincent and the Grenadines',
        height: '14px',
        code:'1784',
        value: 1784,
    },
    {
        pos:'-5478px 0px',
        name:'Samoa',
        height: '10px',
        code:'685',
        value: 685,
    },
    {
        pos:'-4581px 0px',
        name:'San Marino',
        height: '15px',
        code:'378',
        value: 378,
    },
    {
        pos:'-4691px 0px',
        name:'São Tomé and Príncipe (São Tomé e Príncipe)',
        height: '10px',
        code:'239',
        value: 239,
    },
    {
        pos:'-4339px 0px',
        name:'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
        height: '14px',
        code:'966',
        value: 966,
    },
    {
        pos:'-4603px 0px',
        name:'Senegal (Sénégal)',
        height: '14px',
        code:'221',
        value: 221,
    },
    {
        pos:'-4273px 0px',
        name:'Serbia (Србија)',
        height: '14px',
        code:'381',
        value: 381,
    },
    {
        pos:'-4383px 0px',
        name:'Seychelles',
        height: '10px',
        code:'248',
        value: 248,
    },
    {
        pos:'-4559px 0px',
        name:'Sierra Leone',
        height: '14px',
        code:'232',
        value: 232,
    },
    {
        pos:'-4449px 0px',
        name:'Singapore',
        height: '14px',
        code:'65',
        value: 65,
    },
    {
        pos:'-4735px 0px',
        name:'Sint Maarten',
        height: '14px',
        code:'1721',
        value: 1721,
    },
    {
        pos:'-4537px 0px',
        name:'Slovakia (Slovensko)',
        height: '14px',
        code:'421',
        value: 421,
    },
    {
        pos:'-4493px 0px',
        name:'Slovenia (Slovenija)',
        height: '10px',
        code:'386',
        value: 386,
    },
    {
        pos:'-4361px 0px',
        name:'Solomon Islands',
        height: '10px',
        code:'677',
        value: 677,
    },
    {
        pos:'-4625px 0px',
        name:'Somalia (Soomaaliya)',
        height: '14px',
        code:'252',
        value: 252,
    },
    {
        pos:'-5566px 0px',
        name:'South Africa',
        height: '14px',
        code:'27',
        value: 27,
    },
    {
        pos:'-2787px 0px',
        name:'South Korea (대한민국)',
        height: '14px',
        code:'82',
        value: 82,
    },
    {
        pos:'-4669px 0px',
        name:'South Sudan (‫جنوب السودان‬‎)',
        height: '10px',
        code:'211',
        value: 211,
    },
    {
        pos:'-1555px 0px',
        name:'Spain (España)',
        height: '14px',
        code:'34',
        value: 34,
    },
    {
        pos:'-2963px 0px',
        name:'Sri Lanka (ශ්‍රී ලංකාව)',
        height: '10px',
        code:'94',
        value: 94,
    },
    {
        pos:'-4405px 0px',
        name:'Sudan (‫السودان‬‎)',
        height: '10px',
        code:'249',
        value: 249,
    },
    {
        pos:'-4647px 0px',
        name:'Suriname',
        height: '14px',
        code:'597',
        value: 597,
    },
    {
        pos:'-4515px 0px',
        name:'Svalbard and Jan Mayen',
        height: '15px',
        code:'47',
        value: 47,
    },
    {
        pos:'-4779px 0px',
        name:'Swaziland',
        height: '14px',
        code:'268',
        value: 268,
    },
    {
        pos:'-4427px 0px',
        name:'Sweden (Sverige)',
        height: '13px',
        code:'',
        value: 46,
    },
    {
        pos:'-944px 0px',
        name:'Switzerland (Schweiz)',
        height: '15px',
        code:'41',
        value: 41,
    },
    {
        pos:'-4757px 0px',
        name:'Syria (‫سوريا‬‎)',
        height: '14px',
        code:'963',
        value: 963,
    },
    {
        pos:'-5131px 0px',
        name:'Taiwan (台灣)',
        height: '14px',
        code:'886',
        value: 886,
    },
    {
        pos:'-4933px 0px',
        name:'Tajikistan',
        height: '10px',
        code:'992',
        value: 992,
    },
    {
        pos:'-5153px 0px',
        name:'Tanzania',
        height: '14px',
        code:'255',
        value: 255,
    },
    {
        pos:'-4911px 0px',
        name:'Thailand (ไทย)',
        height: '14px',
        code:'66',
        value: 66,
    },
    {
        pos:'-4977px 0px',
        name:'Timor-Leste',
        height: '10px',
        code:'670',
        value: 670,
    },
    {
        pos:'-4889px 0px',
        name:'Togo',
        height: '13px',
        code:'228',
        value: 228,
    },
    {
        pos:'-4955px 0px',
        name:'Tokelau',
        height: '10px',
        code:'690',
        value: 690,
    },
    {
        pos:'-5043px 0px',
        name:'Tonga',
        height: '10px',
        code:'676',
        value: 676,
    },
    {
        pos:'-5087px 0px',
        name:'Trinidad and Tobago',
        height: '12px',
        code:'1868',
        value: 1868,
    },
    {
        pos:'-5021px 0px',
        name:'Tunisia (‫تونس‬‎)',
        height: '14px',
        code:'216',
        value: 216,
    },
    {
        pos:'-5065px 0px',
        name:'Turkey (Türkiye)',
        height: '14px',
        code:'90',
        value: 90,
    },
    {
        pos:'-4999px 0px',
        name:'Turkmenistan',
        height: '14px',
        code:'993',
        value: 993,
    },
    {
        pos:'-4823px 0px',
        name:'Turks and Caicos Islands',
        height: '10px',
        code:'1649',
        value: 1649,
    },
    {
        pos:'-5109px 0px',
        name:'Tuvalu',
        height: '10px',
        code:'688',
        value: 688,
    },
    {
        pos:'-5390px 0px',
        name:'U.S. Virgin Islands',
        height: '14px',
        code:'1340',
        value: 1340,
    },
    {
        pos:'-5197px 0px',
        name:'Uganda',
        height: '14px',
        code:'256',
        value: 256,
    },
    {
        pos:'-5175px 0px',
        name:'Ukraine (Україна)',
        height: '14px',
        code:'380',
        value: 380,
    },
    {
        pos:'-44px 0px',
        name:'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
        height: '10px',
        code:'971',
        value: 971,
    },
    {
        pos:'-1775px 0px',
        name:'United Kingdom',
        height: '10px',
        code:'44',
        value: 44,
    },
    {
        pos:'-5241px 0px',
        name:'United States',
        height: '11px',
        code:'1',
        value: 1,
    },
    {
        pos:'-5263px 0px',
        name:'Uruguay',
        height: '14px',
        code:'598',
        value: 598,
    },
    {
        pos:'-5285px 0px',
        name:'Uzbekistan (Oʻzbekiston)',
        height: '10px',
        code:'998',
        value: 998,
    },
    {
        pos:'-5434px 0px',
        name:'Vanuatu',
        height: '12px',
        code:'678',
        value: 678,
    },
    {
        pos:'-5307px 0px',
        name:'Vatican City (Città del Vaticano)',
        height: '15px',
        code:'39',
        value: 39,
    },
    {
        pos:'-5346px 0px',
        name:'Venezuela',
        height: '14px',
        code:'58',
        value: 58,
    },
    {
        pos:'-5412px 0px',
        name:'Vietnam (Việt Nam)',
        height: '14px',
        code:'84',
        value:  84,
    },
    {
        pos:'-5456px 0px',
        name:'Wallis and Futuna (Wallis-et-Futuna)',
        height: '14px',
        code:'681',
        value: 681,
    },
    {
        pos:'-1511px 0px',
        name:'Western Sahara (‫الصحراء الغربية‬‎)',
        height: '10px',
        code:'212',
        value: 212,
    },
    {
        pos:'-5522px 0px',
        name:'Yemen (‫اليمن‬‎)',
        height: '14px',
        code:'967',
        value: 967,
    },
    {
        pos:'-5588px 0px',
        name:'Zambia',
        height: '14px',
        code:'260',
        value: 260,
    },
    {
        pos:'-5610px 0px',
        name:'Zimbabwe',
        height: '10px',
        code:'263',
        value: 263,
    },
    {
        pos:'-330px 0px',
        name:'Åland Islands',
        height: '13px',
        code:'358',
        value: 358,
    },

    //
    // {
    //     pos:'-1049px 0',
    //     name:'China (中国)',
    //     height: '',
    //     code:'86',
    //     value:86,
    // },
    // {
    //     pos:'-5241px 0',
    //     name:'United States',
    //     height: '',
    //     code:'1',
    //     value:1,
    // },
    // {
    //     pos:'-66px 0',
    //     name:'Afghanistan',
    //     height: '',
    //     code:'93',
    //     value:93,
    // },
    // {
    //     pos:'-5131px 0',
    //     name:'Taiwan (台灣)',
    //     height: '14px',
    //     code:'886',
    //     value:886,
    // },
    // {
    //     pos:'-2787px 0',
    //     name:'South Korea (대한민국)',
    //     height: '',
    //     code:'82',
    //     value:82,
    // },
    // {
    //     pos:'-2611px 0',
    //     name:'Japan (日本)',
    //     height: '',
    //     code:'81',
    //     value:81,
    // },
    // {
    //     pos:'-2985px 0',
    //     name:'Liberia',
    //     height: '',
    //     code:'231',
    //     value:231,
    // },
    // {
    //     pos:'-3578px 0',
    //     name:'Malaysia',
    //     height: '',
    //     code:'60',
    //     value:60,
    // },

]
