<template>
  <div class="helpCenter">
    <div class="section large">
      <div class="container">
        <div class="banner-box">
          <div class="banner-text">
            <div class="_title">{{ $t('helpCenter.helpCenter1') }}</div>
            <div class="page">
              <el-select
                v-model="questionType"
                size="small"
                style="width: 100%"
                @change="faqList"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section main">
      <div class="accordion-row">
        <div class="accordion-col">
          <div
            v-for="item in accordionLeft"
            :key="item.id"
            class="accordion"
          >
            <div class="accordion-title">
              <div class="text">
                {{ item.question }}
              </div>
              <i v-if="item.id === accordionActive" class="el-icon-minus" @click="accordionActive = null"></i>
              <i v-else class="el-icon-plus" @click="accordionActive = item.id"></i>
            </div>
            <el-collapse-transition>
              <div v-if="accordionActive === item.id" class="accordion-content">
                {{ item.answer }}
              </div>
            </el-collapse-transition>
          </div>
        </div>
        <div class="accordion-col">
          <div
            v-for="item in accordionRight"
            :key="item.id"
            class="accordion"
          >
            <div class="accordion-title">
              <div class="text">
                {{ item.question }}
              </div>
              <i v-if="item.id === accordionActive" class="el-icon-minus" @click="accordionActive = null"></i>
              <i v-else class="el-icon-plus" @click="accordionActive = item.id"></i>
            </div>
            <el-collapse-transition>
              <div v-if="accordionActive === item.id" class="accordion-content">
                {{ item.answer }}
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import ElCollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import axios from 'axios'
import { POST_FaqClass } from "@/request/api"
import { POST_FaqList } from "@/request/api"

export default {
  components: {
    ElCollapseTransition
  },
  data() {
    return {
      options: [],
      questionType: '',
      accordions: [],
      accordionActive: null
    }
  },
  computed: {
    accordionLeft() {
      const length = Math.ceil(this.accordions.length / 2)
      return this.accordions.slice(0, length)
    },
    accordionRight() {
      const length = Math.ceil(this.accordions.length / 2)
      return this.accordions.slice(length)
    }
  },
  created() {
    this.getFaqClass();
  },
  methods: {
    getFaqClass: function() {
      var that = this;
      POST_FaqClass().then(function(res){
        if (res.code == 200 || res.code == "200") {
          that.options = res.data;
          that.questionType = that.options[0].id;
          that.faqList();
        }
        else {
          that.$message.error(res.msg);
        }
      });
    },
    getHelpBanner: function() {},
    pruchaseInstructions: function () {},
    useInstructions: function() {},
    faqList: function() {
      var that = this;
      let data = {"id":that.questionType};
      let myheaders = {headers : {"pc":true,"content-type":"multipart/form-data"}};
      POST_FaqList(data, myheaders).then(function(res){
        if (res.code == 200 || res.code == "200") {
          that.accordions = res.data;
        }
        else {
          that.$message.error(res.msg);
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>
  .large {
    padding: 57px 0;
    background: #06155A;
    .banner-box {
      justify-content: center;
      ._title, .page {
        text-align: center;
      }
      .page {
        margin: 13px auto 0;
        width: 30vw;
      }
      @media screen and (max-width: 968px) {
        .page {
          width: 84vw;
        }
      }
    }
  }
</style>

<style lang="less">
.large{font-family: Poppins;font-style: normal;color: #fff;padding: 57px 0;background: #06155a;}
.banner-text ._title {font-weight: 700;font-size: 32px;line-height: 48px;}
.banner-text .page {font-weight: 400;font-size: 20px;line-height: 27px;}
  .helpCenter {
    .section.main {
      width: 70vw;
      margin: 100px auto;
    }
    .accordion-row {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .accordion-col {
        width: calc(50% - 10px);
      }
    }
    .accordion {
      width: 100%;
      background: #EAEFF1;
      border-radius: 10px;
      margin-top: 10px;
      .accordion-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        .text {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #000000;
        }
        > i {
          color: #028BC6;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
          margin-left: 5px;
        }
      }
      .accordion-content {
        padding: 20px 15px;
        border-top: 1px solid #D4D4D4;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #626262;
      }
    }

    @media screen and (max-width: 968px) {
      .section.main {
        width: 100vw;
        padding: 0 12px;
        margin: 30px auto;
      }
      .accordion-row {
        .accordion-col {
          width: 100%;
        }
      }
    }
  }
</style>
