<template>
  <div class="main-content aboutUs">
    <h4 class="textTitle"><span>About eSImart</span></h4>
    <div class="about-box">
      <div class="who">
        <div class="_title">Who Are We?</div>
        <div class="_box">
          <p>Welcome to eSImart, your digital roaming solution expert. We are a dynamic and flexible company committed to providing you with the most convenient and reliable data plans.</p>
        </div>
      </div>
      <div class="items">
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about1.png" /></div>
          <div class="_box">
            <div class="_title">Our Core Values</div>
            <div class="_body">
              <p>At eSImart, our core values revolve around offering customers more flexible data plans. We believe each customer is unique, so we strive to provide personalized services to meet your needs.</p>
            </div>
          </div>
        </div>
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about2.png" /></div>
          <div class="_box">
            <div class="_title">Our Vision</div>
            <div class="_body">
              <p>Our vision is to become your trusted partner in the digital roaming domain. We aim to lead the industry not only by delivering outstanding products but also through proactive and warm customer service. We look forward to finding the most suitable solution, making your travel and communication experiences more seamless and enjoyable.</p>
            </div>
          </div>
        </div>
        <div class="_item">
          <div class="_imgBox"><img src="@/assets/image/about3.png" /></div>
          <div class="_box">
            <div class="_title">Our Story</div>
            <div class="_body">
              <p>Established in 2021, eSImart founded to address the challenges faced by users who often forget to purchase SIM cards before departure or lose physical SIM cards during their journeys. Introducing eSIM technology, we ensure you receive your order within 24 hours, significantly simplifying the roaming process and providing a more convenient option for your digital roaming needs.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="globalP">
        <div class="_title">Global Partnerships</div>
        <div class="_body">
          <p>We partner with telecom leaders worldwide, such as Softbank, Docomo, KDDI in Japan, SKT & KT in South Korea, and key providers in Southeast Asian destinations like TrueMove and Viettel. Collaborations extend to Europe with KPN, 3U, and Giffgaff. Our expansive network covers 100+ countries and regions across Asia, Europe, the Americas, and Oceania. As we strive for global coverage, eSImart is dedicate to offering travelers reliable and customizable network services.</p>
        </div>
      </div>
      <div class="thank">
        <p>Thank you for choosing eSImart.<br />
          Let's make your journey and communication experience even smoother!</p>
      </div>
    </div>









  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      tabIndex:1,
      searchTwo:'',
      ios1:[
        {
          id: 1,
          name: "Use Camera to scan QRcode from eSImart",
          img: require("@/assets/image/works/ios1.jpg")
        },
        {
          id: 2,
          name: "Tap Continue to Activate eSIM at your phone",
          img: require("@/assets/image/works/ios2.jpg")
        },
        {
          id: 3,
          name: "Add cellular plan and label it",
          img: require("@/assets/image/works/ios3.jpg")
        },
        {
          id: 4,
          name: "Choose ‘Primary’ for Default Line",
          img: require("@/assets/image/works/ios4.jpg")
        },
        {
          id: 5,
          name: "Choose ‘Primary’ for iMessage & FaceTime",
          img: require("@/assets/image/works/ios5.jpg")
        },
        {
          id: 6,
          name: "Choose ‘Primary’ for Cellular Data",
          img: require("@/assets/image/works/ios6.jpg")
        },
      ],
      // 产品列表
      dataList: [

      ],
      ios:true
    };
  },
  created() {

  },
  methods: {

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>

</style>
