<template>
  <div class="details">
    <div class="main-content">
      <div class="support-title tutorial-title">
        How to use eSIM
      </div>
      <div class="support-body tutorial-body">
        <h5>If you are using iOS device, you can follow these steps:</h5>
        <p>1.	Open Settings: Tap on the "Settings" app on your iOS device's home screen.</p>
        <p>2.	Tap on "Cellular": Scroll down and tap on "Cellular" to access your cellular settings. </p>
        <p>3.	Look for "Cellular Plans": If your iOS device supports eSIM, you should see an option called "Cellular Plans" under the "Cellular" section. Tap on "Cellular Plans".</p>
        <p>4.	Look for the "Add a New Plan" option: If your iOS device supports eSIM, you should see an option to "Add a New Plan". This means your device is eSIM compatible and can support a secondary cellular plan using eSIM technology.</p>
        <br />
        <p>To see more detail of APPLE iOS version, please click here: </p>
        <p><a href="https://support.apple.com/en-us/HT212780">https://support.apple.com/en-us/HT212780</a></p>
        <p>In case you can not make sure if your device can support eSIM or not, you may also dial *#06#. Once you see any related EID barcode or wording, means your device supports eSIM.</p>
        <br />
        <br />
        <h5>If you are using Android device, you can follow these steps:</h5>
        <p>1.	Open Settings: Tap on the "Settings" app on your Android device's home screen.</p>
        <p>2.	Tap on "Network & internet": This will open the Network & internet settings menu.</p>
        <p>3.	Look for "Mobile network" or "Cellular network": Tap on the option that refers to your device's mobile or cellular network settings.</p>
        <p>4.	Look for "Advanced": Depending on your device, you may need to tap on an "Advanced" or "Additional settings" option to see more options.</p>
        <p>5.	Look for "Carrier": If your Android device supports eSIM, you should see an option for "Carrier". Tap on this option.</p>
        <p>6.	Look for "Add carrier" or "Add profile": If your Android device supports eSIM, you should see an option to "Add carrier" or "Add profile". This means your device is eSIM compatible and can support a secondary cellular plan using eSIM technology.</p>
        <br />
        <p>In case you can not make sure if your device can support eSIM or not, you may also dial *#06#. Once you see any related EID barcode or wording, means your device supports eSIM.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>


</style>
