<template>
  <div class="main-content affiliate">
    <h4 class="textTitle">Affiliate Partners</h4>
    <h2 class="title">Welcome to the future of connectivity! </h2>
    <div class="affiliate-list-box">
      <div class="row md-2 future affiliate-list">
        <div class="col _textP">
          <p> We are thrilled to introduce you to our cutting-edge eSIM site, where the possibilities for seamless global communication are endless.</p>
          <p>  As the world becomes increasingly interconnected, staying connected is no longer a luxury but a necessity. This is where our eSIM technology comes in.</p>
        </div>
        <div class="col _icoImg">
          <img src="@/assets/image/affiliate/ico-saly.png" />
        </div>
      </div>
      <div class="row md-2 fast-paced affiliate-list">
        <div class="col _textP">
          <p> In this fast-paced digital era, traditional physical SIM cards can be cumbersome, restrictive, and often limit our ability to stay connected while on the go. However, with eSIM technology, we have revolutionized the way people access and manage their mobile connections.</p>
        </div>
        <div class="col _icoImg">
          <div class="ico-frame">
            <img class="frame-1" src="@/assets/image/affiliate/frame-1.png" />
            <img class="frame-2" src="@/assets/image/affiliate/frame-2.png" />
          </div>
        </div>
      </div>
    </div>

    <div class="container-shifting">


    </div>


    <div class="site-message">
      <div class="round-dot">
        <div class="_dot"></div>
        <div class="_dot"></div>
        <div class="_dot"></div>
      </div>
      <div class="_box">
        <div class="_max-container">
          <p>At our eSIM site, we offer a wide range of eSIM solutions that empower individuals and businesses to enjoy hassle-free connectivity across borders. Our user-friendly platform allows customers to easily download and activate eSIM profiles for their devices, eliminating the need for physical SIM cards and providing greater flexibility and convenience.</p>

          <p>But we're not stopping there. We are actively seeking affiliate partners who share our vision of a world without boundaries, and who are passionate about providing exceptional connectivity solutions to their customers. By partnering with us, you can tap into a rapidly growing market and offer your customers a unique value proposition.</p>

        </div>
      </div>
    </div>

    <div class="as-box">
      <div class="as-wrap">
        <p>As an affiliate partner, you'll gain access to a comprehensive suite of marketing materials, resources, and support from our dedicated team. We'll work closely with you to develop customized strategies that align with your audience and business goals, ensuring mutual success.</p>

        <p>Joining forces with us means becoming part of a dynamic ecosystem that is shaping the future of connectivity. Together, we can unlock new opportunities, expand reach, and enhance customer experiences. Whether you're a tech influencer, a telecommunications provider, an e-commerce platform, or any other industry professional, we believe there's immense potential for collaboration.</p>
      </div>
      <div class="round-dot">
        <div class="_dot"></div>
        <div class="_dot"></div>
        <div class="_dot"></div>
      </div>
    </div>
    <div class="so-box">
      <div class="so-wrap">
        <div class="so-ico-box"></div>
        <p>As an affiliate partner, you'll gain access to a comprehensive suite of marketing materials, resources, and support from our dedicated team. We'll work closely with you to develop customized strategies that align with your audience and business goals, ensuring mutual success.</p>

        <p>Joining forces with us means becoming part of a dynamic ecosystem that is shaping the future of connectivity. Together, we can unlock new opportunities, expand reach, and enhance customer experiences. Whether you're a tech influencer, a telecommunications provider, an e-commerce platform, or any other industry professional, we believe there's immense potential for collaboration.</p>
      </div>
    </div>


    <a href="/affiliateSign" class="affiliate-btn"><span>Sign up</span></a>





  </div>
</template>

<script>

export default {
  name: "home",
  components: {},
  data() {
    return {
      search:'',
      tabIndex:1,
      searchTwo:'',
      ios1:[
        {
          id: 1,
          name: "Use Camera to scan QRcode from eSImart",
          img: require("@/assets/image/works/ios1.jpg")
        },
        {
          id: 2,
          name: "Tap Continue to Activate eSIM at your phone",
          img: require("@/assets/image/works/ios2.jpg")
        },
        {
          id: 3,
          name: "Add cellular plan and label it",
          img: require("@/assets/image/works/ios3.jpg")
        },
        {
          id: 4,
          name: "Choose ‘Primary’ for Default Line",
          img: require("@/assets/image/works/ios4.jpg")
        },
        {
          id: 5,
          name: "Choose ‘Primary’ for iMessage & FaceTime",
          img: require("@/assets/image/works/ios5.jpg")
        },
        {
          id: 6,
          name: "Choose ‘Primary’ for Cellular Data",
          img: require("@/assets/image/works/ios6.jpg")
        },
      ],
      // 产品列表
      dataList: [

      ],
      ios:true
    };
  },
  created() {

  },
  methods: {

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>

</style>
