<template>
  <div class="product">
    <div class="home-box">
      <h1 class="title">eSIM product</h1>
      <div class="product-title">
        <ul>
          <li v-for="(item,i) in fenleiList" :key="i" :class="i == index?'active':''" style="cursor: pointer;" @click="selectClassification(item,i)">{{ item.name }}</li>
        </ul>
      </div>
      <div class="filter-box">
        <div class="items">
          <div class="item" :class="{'active':item.id === filterIndex}" @click="changeFilter(item)" v-for="(item,index) in filterList" :key="index">{{item.label}}</div>
        </div>
      </div>
      <div class="home-box-list">
        <div class="list-div" v-for="(item, index) in dataList" :key="index" @click="toDetails(item)">
          <div class="list-div-img">
            <img class="img" :src="item.image" alt="">
            <div class="shop" @click="toShop(item)">
              <img src="@/assets/image/icon/icon-shop.png" alt="">
            </div>
            <div class="love" @click.stop="toLove(item)">
              <img src="@/assets/image/icon/icon-love.png" alt="">
            </div>
          </div>
          <h2 class="list-div-h2">{{item.h2}}</h2>
          <h2 class="list-div-h4">{{item.sketch}}</h2>
          <h3 class="list-div-h3">{{item.name}}</h3>
          <h4 class="list-div-h4 color-FFC700">
            From USD <span>{{'$ ' + item.min_price.toLocaleString()}}</span>
          </h4>
        </div>
      </div>
<!--      dataList.length > 8-->
      <div class="text-center more" v-if="false">
        <a href="#" class="btn btn-success" @click="page++">Load more</a>
      </div>
      <div class="pagination-box">
        <el-pagination
            background
            small
            :page-sizes="[20, 30, 50]"
            :page-size="form.limit"
            :current-page="form.page"
            layout=" prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>

    </div>
  </div>
</template>

<script>
// 引入插件

import { slice } from "methods";
import "swiper/dist/css/swiper.css";
import {getfenlei,getProduct} from '../../request/api'
export default {
  name: "home",
  components: {},
  data() {
    return {
      // 产品列表
      dataList: [

      ],
      page: 1,

      fenleiList: [],
      index: 0,
      filterIndex:'',
      filterFirst: [],
      filterList:[
        {id: 1, label: 'A-B', value: ['a', 'b'] },
        {id: 2, label: 'C-E', value: ['c', 'd', 'e'] },
        {id: 3, label: 'F-H', value: ['f', 'g', 'h'] },
        {id: 4, label: 'I-L', value: ['i', 'j', 'k', 'l'] },
        {id: 5, label: 'M-O', value: ['m', 'n', 'o'] },
        {id: 6, label: 'P-R', value: ['p', 'q', 'r'] },
        {id: 7, label: 'S-T', value: ['s', 't'] },
        {id: 8, label: 'U-Z', value: ['u', 'v', 'w', 'x', 'y', 'z'] }
      ],
      form: {
        page: 1,
        limit: 20,
        first_code: null,
        type_id: null
      },
      total: 0
    };
  },
  computed: {
    // filterDataList() {
    //   if (this.filterFirst && this.filterFirst.length) {
    //     return this.dataList.filter(v => {
    //       let bool = false
    //       for (const i of this.filterFirst) {
    //         if (v.name.toLowerCase().startsWith(i)) {
    //           bool = true
    //         }
    //       }
    //       return bool
    //     })
    //   }
    //   return this.dataList
    // }
  },
  mounted(){
     this.fenlei();

  },
  methods: {
    // 跳转至产品详情
    toDetails(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入购物车
    toShop(item) {
      this.$router.push({
        path: '/details',
        query: {
          id: item.id
        }
      })
    },
    // 加入我喜欢
    toLove(item) {

    },

    //分類
   async fenlei(){
      let res = await getfenlei();
      if(res.code == 1){
        // this.fenleiList = res.data;
        this.fenleiList = res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id));;

        this.form.type_id = res.data[0].id
        this.form.page = 1
        this.getProductList()
      }
    },

    //選擇分類
  selectClassification(item,i){
      this.filterIndex = '';
      this.filterFirst = [];
       this.index = i;
       this.form.first_code = ''
       this.form.type_id = item.id
        this.form.page = 1
       this.getProductList()
    },

    //獲取產品列表
   async getProductList(){
      let res = await getProduct({
        ...this.form
      })
      if(res.code == 1){
          this.dataList = res.data.plan
        this.total = res.data.total
      }
    },
    changeFilter(item) {
      this.filterFirst = item.label
      this.filterIndex = item.id
      this.form.first_code = item.label
      this.form.page = 1
      this.getProductList()
    },
    handleSizeChange(val) {
      this.form.limit = val
      this.form.page = 1
      this.getProductList()
    },
    handleCurrentChange(val) {
      this.form.page = val
      this.getProductList()
    }
  }
}
</script>

<style lang="less" scoped>
.product {
  padding-bottom: 90px;

  .home-box {
    margin: 40px auto 0;
    width: 1174px;
    .home-box-title {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 46px;
        color: #FFFFFF;
      }
      div {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #FFFFFF;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .home-box-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .list-div {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 45px;
        width: 278px;
        .list-div-img {
          width: 100%;
          height: 186px;
          border-radius: 5px;
          position: relative;
          margin-bottom: 17px;
          .img {
            width: 100%;
            height: 100%;
          }
          .shop {
            position: absolute;
            right: 55px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 23px;
              height: 23px;
            }
          }
          .love {
            position: absolute;
            right: 10px;
            bottom: 10px;
            width: 35px;
            height: 35px;
            background: rgba(0, 0, 0, 0.5);
            border-radius: 50%;
            display: none;
            align-items: center;
            justify-content: center;
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
        .list-div-h2 {
          padding: 0 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #00A2FD;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .list-div-h3 {
          padding: 2px 8px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
        }
        .list-div-h4 {
          padding: 0 8px 0;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #00A2FD;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          i {
            font-size: 12px;
            margin-left: 10px;
            font-style: normal;
          }
        }
        .color-FFC700 {color: #FFC700;}
      }
      .list-div:nth-child(4n+4) {
        margin-right: 0;
      }
      .list-div:hover {
        .list-div-img {
          .shop {
            display: flex;
          }
          .love {
            display: flex;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .product {
    .home-box {
      width: 1000px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .product {
    .home-box {
      width: 780px;
      .home-box-list {
        .list-div {
          width: calc((100% - 40px) / 3);
          .list-div-img {
            width: 100%;
            height: 164px;
          }
        }
        .list-div:nth-child(4n+4) {
          margin-right: 20px;
        }
        .list-div:nth-child(3n+3) {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .product {
    .home-box {
      padding: 0 20px;
      width: calc(100% - 40px);
      .home-box-title {
        margin-bottom: 12px;
        h1 {
          font-size: 16px;
          line-height: 23px;
        }
        div {
          font-size: 12px;
          line-height: 17px;
          img {
            width: 23px;
            height: 23px;
          }
        }
      }
      .home-box-list {
        .list-div {
          width: calc((100% - 20px) / 2);
          .list-div-img {
            width: 100%;
            height: 105px;
          }
          .list-div-h2 {
            padding: 12px 0 0;
            font-size: 10px;
            line-height: 14px;
          }
          .list-div-h3 {
            padding: 3px 0 0;
            font-size: 14px;
            line-height: 20px;
          }
          .list-div-h4 {
            padding: 0;
            font-size: 10px;
            line-height: 15px;
            span {
              font-size: 14px;
              line-height: 20px;
            }
            i {
              font-size: 10px;
              line-height: 20px;
              margin-left: 6px;
            }
          }
        }
        .list-div:nth-child(3n+3) {
          margin-right: 20px;
        }
        .list-div:nth-child(2n+2) {
          margin-right: 0;
        }
        .list-div:hover {
          .list-div-img {
            .shop {
              display: none;
            }
            .love {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
