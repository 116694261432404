<template>
  <div class="details">
    <h2 class="details-h2">
      <span @click="$router.push('/')">Home</span>
      <!-- / {{ Prouductdata.area_name }} / {{ Prouductdata.country_name }}  -->
      / {{
        Prouductdata.name
      }}
    </h2>
    <div class="details-content">
      <div class="content-left">
        <!-- <img src="@/assets/image/product/details1.png" alt=""> -->
        <img :src="Prouductdata.image" alt="">
      </div>
      <div class="content-right">
        <h1 class="content-right-h1">{{ Prouductdata.name }}</h1>
        <h2 class="content-right-h2">{{ Prouductdata.sketch }}</h2>
<!--        <div class="content-right-select">-->
<!--          <span>Specifications</span>-->
<!--          <el-select v-model="value" placeholder="Please choose" no-data-text="Countless data">-->
<!--            <el-option v-for="item in Prouductdata.price_list" :key="item.id" :label="item.spec" :value="item.id">-->
<!--            </el-option>-->
<!--          </el-select>-->
<!--        </div>-->
        <div class="content-right-select">
          <span>Specifications</span>
          <el-select v-model="value" placeholder="Please choose" no-data-text="Countless data" @change="activeProduct = null">
            <el-option v-for="item in Prouductdata.days_list" :key="item" :label="item + ' Days'" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="price-box">
          <template v-for="item in priceList">
            <div v-if="item.package" :class="{ active: activeProduct === item.id }" @click="activeProduct = item.id" :key="item.id" class="item">
              <div class="itemBox">
                <h3>{{ item.spec }} <small v-if="item.package && item.package.includes('By Day')">/ Day</small></h3>
                <p v-if="item.package && item.package.includes('Total')">Total</p>
                <p v-else>{{ item.package.at(-1) }}</p>
              </div>
            </div>
          </template>
        </div>

        <div class="content-right-price">
          <div class="oldPrice">
            Was
            <span>${{
              activePrice.amount?activePrice.amount.toLocaleString():activePrice.amount || '0.00'
            }}</span>
          </div>
          <div class="newPrice">
            Now
            <p>USD <span>${{
              activePrice.special_amount?activePrice.special_amount.toLocaleString():activePrice.special_amount || '0.00'
            }}</span><i v-if="false">= NTD {{
              activePrice.special_amount ? (activePrice.special_amount * rate).toFixed(1) : '0.00'
            }}</i></p>
          </div>
        </div>

<!--        <div class="content-right-price">-->
<!--          <div class="oldPrice">-->
<!--            Was-->
<!--            <span>${{-->
<!--              value? Prouductdata.price_list[Prouductdata.price_list.findIndex(fitem => fitem.id ==-->
<!--                value)].amount : 0-->
<!--            }}</span>-->
<!--          </div>-->
<!--          <div class="newPrice">-->
<!--            Now-->
<!--            <p>USD <span>${{-->
<!--              value? Prouductdata.price_list[Prouductdata.price_list.findIndex(fitem => fitem.id ==-->
<!--                value)].special_amount : 0-->
<!--            }}</span><i>= NTD {{ value? Prouductdata.price_list[Prouductdata.price_list.findIndex(fitem => fitem.id ==-->
<!--                value)].special_amount*32 : 0 }}</i></p>-->
<!--          </div>-->
<!--        </div>-->
        <div class="content-right-num">
          <div class="counter">
            <span @click="less">-</span>
            <div>{{ num }}</div>
            <span @click="add">+</span>
          </div>
          <div class="loves" @click="toLoves" v-if="false">
            <img src="@/assets/image/icon/icon-loves.png" alt="">
          </div>
          <div class="share" @click="toShare" v-if="false">
            <img src="@/assets/image/icon/icon-share.png" alt="">
          </div>
        </div>
        <div class="content-right-button">
          <div class="button1" @click="toCart(1)">Add to cart</div>
          <div class="button2" @click="toCart(2)">Purchase now</div>
        </div>
        <div class="content-right-share" v-if="false">
          <div @click="toLoves" v-if="false">
            <img src="@/assets/image/icon/icon-loves.png" alt="">
            Add to my favorites
          </div>
          <div @click="toShare">
            <img src="@/assets/image/icon/icon-share.png" alt="">
            Share
          </div>
        </div>
      </div>
    </div>
    <div class="details-tag">
      <span @click="$router.push({
        path: '/search',
        query: {
          content: item.title
        }
      })" v-for="(item, index) in Prouductdata.tag_list" :key="index">{{ '# ' + item.title }}</span>
    </div>
    <div class="details-tab">
      <div :class="tabActive == item.type ? 'tab-div tab-active' : 'tab-div'" v-for="(item, index) in tabList"
        :key="index" @click="tabChange(item.type)">
        {{ item.title }}
        <span></span>
      </div>
    </div>
    <div class="details-intro" v-html="tabActive == 1 ? Prouductdata.detail_text : tabActive == 2 ? Prouductdata.spec_text : Prouductdata.use_text">
    </div>
    <div class="details-intro pc" v-html="tabActive == 1 ? Prouductdata.detail_text_mobile : tabActive == 2 ? Prouductdata.spec_text_mobile : Prouductdata.use_text">
    </div>
  </div>
</template>

<script>
import { getProductDetails, getCart, placeOrder, getCurrentRate } from '@/request/api'
import {getGroupByDays} from "../../request/api";
export default {
  name: 'Details',
  data() {
    return {
      num: 1,
      value: '',
      options: [

      ],
      tagList: [],
      Prouductdata: {
        name: 'eSImart'
      },
      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: 'Plan details'
        },
        {
          type: 2,
          title: 'Technical specification'
        },
        // {
        //   type: 3,
        //   title: 'How to use'
        // },
      ],
      activeProduct: null,
      rate:0,// 匯率
    }
  },
  metaInfo() {
    return {
      title: `${this.Prouductdata.name} - Global eSIMs for Sale at eSimart.com`,
      meta: [
        { name: 'Title', content: `${this.Prouductdata.name} - Buy it at eSIMmart`, vmid: 'Title' },
        { name: 'Author', content: 'eSImart', vmid: 'Author' },
        { name: 'Description', content: `${this.Prouductdata.seo_decription}-meta description`, vmid: 'Description' },
        { name: 'Keywords', content: `${this.Prouductdata.seo_keyword}-meta keywords`, vmid: 'Keywords' },
        { name: 'Language',content: 'English', vmid: 'Language' },
        { name: 'og:type', content: 'article', vmid: 'og:type' },
        { name: 'og:image', content: this.Prouductdata.seo_image, vmid: 'og:image' },
        { name: 'og:site_name', content: this.Prouductdata.seo_sitename, vmid: 'og:site_name' },
        { name: 'og:description', content: `${this.Prouductdata.seo_decription}-meta description`, vmid: 'og:description' },
        { name: 'og:url', content: window.location.href, vmid: 'og:url' },
        { name: 'og:title', content: `${this.Prouductdata.name} - Global eSIMs for Sale at eSimart.com`, vmid: 'og:title' }
      ]
    }
  },
  computed: {
    priceList() {
      if (this.value) {
        return this.Prouductdata.price_list[this.value]
      }
      return []
    },
    activePrice() {
      if (this.activeProduct) {
        return this.priceList.find(v => v.id === this.activeProduct)
      }
      return {}
    }
  },
  created() {
    // this.getPrcoduct();
    this.getGroupByDays()
    this.getCurrentRate()

  },
  mounted() {
    // console.log(this.Prouductdata.name)
  },
  methods: {
   send_to_page(){
     let event_config = {
       items: [
         {
           item_id: this.$route.query.id,
           item_name: this.Prouductdata.name,
         }
       ]
     }
     console.log(event_config)
     this.$gtag.event('view_item', event_config);
   },
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num = Number(this.num) + Number(1);
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    //去購物車
    async toCart(i) {

      if (i == 1) {
        if (this.activeProduct && this.num > 0) {
          let event_config = {
            currency: "USD",
            value: (this.num * this.activePrice.special_amount).toFixed(1),
            items: [
              {
                item_id: this.$route.query.id,
                item_name: this.Prouductdata.name,
                price: this.activePrice.special_amount,
                quantity: this.num
              }
            ]
          }
          console.log(event_config)
          this.$gtag.event('add_to_cart', event_config);
          if(window.localStorage.getItem('TOKEN_NAME')){
          let data = {
            plan_id: this.$route.query.id,
            price_id: this.activeProduct,
            num: this.num
          }
          let res = await getCart(data);
          // this.$router.push({
          //   path: '/cart',
          //   query: {}
          // })
          this.$store.commit('changgetCart')
        } else {
            let shopCart;
            if(window.sessionStorage.getItem('shopCart')){
                shopCart = JSON.parse(window.sessionStorage.getItem('shopCart'))
            }else{
                shopCart = []
            }
            let data = {
              name:this.Prouductdata.name,
              sketch:this.Prouductdata.sketch,
              special_amount: this.activePrice.special_amount,
              num:this.num,
              image:this.Prouductdata.image,
              plan_id:this.Prouductdata.id,
              price_id:this.activeProduct,
              spec:this.activePrice.spec,
              days:this.value,
              package:this.activePrice.package
            }
            // console.log('data',data)
            if(shopCart.findIndex(iten =>iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct) != -1){
              shopCart[shopCart.findIndex(iten =>iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct)].num =Number(shopCart[shopCart.findIndex(iten =>iten.plan_id == this.Prouductdata.id && iten.price_id == this.activeProduct)].num)+Number(this.num)
            }else{
              shopCart.push(data);
            }
            window.sessionStorage.setItem('shopCart',JSON.stringify(shopCart))
            // this.$router.push({
            //   path: '/cart',
            //   query: {}
            // })
            this.$store.commit('changgetCart')
          }

        } else if (!this.activeProduct) {
          this.$message({
            type: 'warning',
            message: 'Please select the specification'
          })
        } else if (this.num < 1) {
          this.$message({
            type: 'warning',
            message: 'Please select the quantity'
          })
        }

        this.$message({
          type: 'success',
          message: 'Added successfully'
        })
      } else if (i == 2) {
        let list = []
        if (this.activeProduct && this.num > 0) {
          let event_config = {
            currency: "USD",
            value: (this.num * this.activePrice.special_amount).toFixed(1),
            items: [
              {
                item_id: this.$route.query.id,
                item_name: this.Prouductdata.name,
                price: this.activePrice.special_amount,
                quantity: this.num
              }
            ]
          }
          this.$gtag.event('purchase_now', event_config);
          let obj = {
            id: this.$route.query.id,
            price_id: this.activeProduct,
            num: this.num
          }
         list.push(obj)
         let data = {
            plan:list
         }
          let res = await placeOrder(data);
          if (res.code == 3) {
            this.$store.commit('changgetCart')
            this.$router.push({
              path: '/closing',
              query:{
                 id:res.data.order_id
              }
            })
          }
        } else if (!this.activeProduct) {
          this.$message({
            type: 'warning',
            message: 'Please select the specification'
          })
        } else if (this.num < 1) {
          this.$message({
            type: 'warning',
            message: 'Please select the quantity'
          })
        }
      }
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },

    //獲取產品詳情
    async getPrcoduct() {
      let data = {
        id: this.$route.query.id
      }
      let res = await getProductDetails(data);
      this.Prouductdata = res.data;
    },

    setMeta(property, content) {
      const existingMeta = document.querySelector(`meta[property="${property}"]`)
      if (existingMeta) {
        existingMeta.setAttribute('content', content)
      } else {
        const meta = document.createElement('meta')
        meta.setAttribute('property', property)
        meta.setAttribute('content', content)
        document.head.appendChild(meta)
      }
    },

    async getGroupByDays() {
      const res = await getGroupByDays({ id: this.$route.query.id })
      this.Prouductdata = res.data
      // this.$nextTick(() => {
      //   this.send_to_page()
      // })
    },

    async getCurrentRate() {
      const res = await getCurrentRate({})
      // console.log(Number(res.data.rate))
      this.rate = Number(res.data.rate)
    }
  }
}
</script>

<style lang="less" scoped>
.details-intro{}
.pc{display: none;}
@media screen and (max-width: 800px) {
  .details-intro{display: none;}
  .details-intro.pc{display: block;}
}
.details {
  padding: 46px 0;
  margin: 0 auto;
  width: 1134px;


  .details-h2 {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .details-content {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;

    .content-left {
      width: 500px;
      height: 335px;
      border-radius: 5px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content-right {
      padding: 16px 25px;
      width: 568px;
      //height: 303px;
      background: #1D1D1D;
      border-radius: 10px;

      .content-right-h1 {
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #FFFFFF;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-h2 {
        margin: 6px 0 16px;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #00A2FD;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-select {
        display: flex;
        align-items: center;

        span {
          margin-right: 24px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: #FFFFFF;
        }

        /deep/ .el-select {
          width: 170px;
          height: 30px;
          background: #F2F2F2;
          border-radius: 5px;

          .el-input {
            width: 100%;
            height: 100%;

            .el-input__inner {
              width: 100%;
              height: 100%;
              font-family: 'Noto Sans TC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 30px;
              color: #000000;
            }

            .el-input__icon {
              line-height: 30px;
            }
          }
        }
      }

      .content-right-price {
        margin: 10px 0 16px;
        display: flex;
        align-items: center;
        font-family: 'Noto Sans TC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #FFFFFF;

        .oldPrice {
          span {
            margin-left: 22px;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            text-decoration-line: line-through;
            color: #616161;
          }
        }

        .newPrice {
          margin-left: 20px;
          display: flex;
          align-items: center;

          p {
            margin-left: 10px;
            font-family: 'Noto Sans TC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #F74343;

            span {
              font-size: 24px;
              line-height: 30px;
            }
            i {
              font-size: 14px;
              line-height: 30px;
              font-style: normal;
              margin-left: 10px;
            }
          }
        }
      }

      .content-right-num {
        display: flex;

        .counter {
          display: flex;
          align-items: center;
          min-width: 86px;
          height: 34px;
          background: #F2F2F2;
          border-radius: 5px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #000000;

          span {
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 28px;
            height: 100%;
            font-size: 18px;
            line-height: 34px;
          }

          div {
            min-width: calc(100% - 56px);
            text-align: center;
          }
        }

        .loves {
          display: none;
        }

        .share {
          display: none;
        }
      }

      .content-right-button {
        margin: 25px 0 23px;
        display: flex;
        align-items: center;

        div {
          cursor: pointer;
          user-select: none;
          width: 145px;
          height: 37px;
          border-radius: 20px;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .button1 {
          background: #0B57C9;
        }

        .button2 {
          margin-left: 5px;
          background: #219D71;
        }
      }

      .content-right-share {
        display: flex;
        align-items: center;

        div {
          cursor: pointer;
          user-select: none;
          margin-right: 32px;
          display: flex;
          align-items: center;
          font-family: 'Noto Sans TC';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #D9D9D9;

          img {
            margin-right: 9px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .details-tag {
    margin: 20px 0 35px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #B9E6FF;

    span {
      margin-right: 19px;
      cursor: pointer;
    }
  }

  .details-tab {
    display: flex;
    align-items: center;

    .tab-div {
      cursor: pointer;
      user-select: none;
      margin-right: 36px;
      text-align: center;
      font-family: 'Noto Sans TC';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
      color: #D9D9D9;

      span {
        margin-top: 6px;
        display: block;
        width: 97px;
        height: 5px;
        background: transparent;
        border-radius: 5px;
      }
    }

    .tab-active {
      font-weight: 500;
      font-size: 24px;

      span {
        background: linear-gradient(90deg, #00A2FD 8.25%, #A0DDFF 100%);
      }
    }
  }

  .details-intro {
    padding: 30px 0 0;
    width: 867px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
  }
}

@media screen and (max-width: 1280px) {
  .details {
    width: 1000px;

    .details-content {
      .content-right {
        width: calc(100% - 568px);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .details {
    width: 780px;

    .details-content {
      flex-wrap: wrap;

      .content-left {
        width: 100%;
      }

      .content-right {
        margin-top: 18px;
        width: calc(100% - 50px);
      }
    }

    .details-intro {
      width: 100%;
    }
  }
}

@media screen and (max-width: 800px) {
  .details {
    padding: 0;
    width: 100%;

    .details-h2 {
      display: none;
    }

    .details-content {
      flex-wrap: wrap;

      .content-left {
        width: 100%;
        height: 250px;
        border-radius: 0;
      }

      .content-right {
        padding: 0 20px;
        margin-top: 0;
        width: calc(100% - 40px);
        height: auto;
        background: none;

        .content-right-h1 {
          margin-top: 10px;
          white-space: normal;
          overflow: visible;
          text-overflow: auto;
        }

        .content-right-h2 {
          margin: 5px 0 12px;
          white-space: normal;
          overflow: visible;
          text-overflow: auto;
        }

        .content-right-select {
          display: block;

          span {
            margin-bottom: 8px;
            display: block;
          }

          /deep/ .el-select {
            width: 100%;
            height: 38px;

            .el-input {
              .el-input__inner {
                line-height: 38px;
              }

              .el-input__icon {
                line-height: 38px;
              }
            }
          }
        }

        .content-right-price {
          margin: 8px 0 13px;

          .oldPrice {
            span {
              margin-left: 9px;
              font-size: 16px;
              line-height: 23px;
            }
          }

          .newPrice {
            margin-left: 20px;
            p{
              i{
                font-size: 12px;
              }
            }

          }
        }

        .content-right-num {
          .loves {
            cursor: pointer;
            user-select: none;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            background: #F2F2F2;
            border-radius: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }

          .share {
            cursor: pointer;
            user-select: none;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            background: #F2F2F2;
            border-radius: 5px;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }

        .content-right-button {
          margin: 32px 0 64px;

          div {
            width: calc((100% - 7px) / 2);
          }
        }

        .content-right-share {
          display: none;
        }
      }
    }

    .details-tag {
      margin: 0 0 26px;
      text-align: center;

      span {
        margin: 0 10px;
      }
    }

    .details-tab {
      padding: 0 20px;
      justify-content: space-between;

      .tab-div {
        margin-right: 0;
      }
    }

    .details-intro {
      padding: 20px;
      width: calc(100% - 40px);
    }
  }
}
</style>
