<template>
  <div class="details">
    <div class="main-content">
      <div class="terms-title">
        Terms & Conditions
      </div>
      <div class="terms-body">
        <p>By agreeing and using our eSIM product, you will become a eSImart eSIM service user. Before doing so, please carefully read this agreement.  Using this service is regarded as acceptance of all the terms of this agreement. </p>

        <h5>1. Service Introduction</h5>
        <p>1.1 Service Description</p>
        <p>eSImart eSIM is a mobile network service product provided by eSImart .com ("we" or "us"). This service helps outbound travelers stay connected in designated regions, offering affordable and convenient data service purchases through our app and website.</p>
        <p>1.2 Service Coverage</p>
        <div id="refundPolicy"></div>
        <p>Our service expands to cover more countries and regions over time.</p>
        <h5>2. Refund Policy</h5>
        <p>2.1 Refund Request Deadline: Request before order confirmation email. No refunds for scanned QR codes or installations.</p>
        <p>2.2 Application Process: Provide order number, product name, refund reason to service@esimart.com.</p>
        <p>2.3 Refund Method: Refunds to the original payment method in approximately 30 business days.</p>
        <h5>&nbsp;</h5>
        <p>In the event of any eSIM-related issues during your journey, it is imperative that you contact our support team for confirmation. Ｗe will assess whether the problem falls within our responsibility, and refunds or exchanges will only be initiated in confirmed cases. Please reiterate that refund requests made after returning to your home country without prior confirmation will not be processed.</p>

        <h5>3. Payment</h5>
        <p>Purchase with international credit cards when on a network accessible to eSImart's website.</p>

        <h5>4. Abnormality</h5>
        <p>We are not responsible for service unavailability due to various conditions, including network issues, natural disasters, and equipment failures.</p>

        <h5>5. Disclaimer</h5>
        <p>Use services responsibly to avoid hindering others' experiences, as we may limit speed or terminate service to prevent excessive data usage.</p>

        <h5>6. Privacy Protection</h5>
        <p>Agree to our Privacy Policy; data collection for service enhancement; data security measures in place.</p>

        <h5>7. Use Limit</h5>
        <p>No altering, disassembling, or reverse-engineering the service.</p>

        <h5>8. Changes to Terms</h5>
        <p>We may occasionally revise this agreement; your continued use implies acceptance of changes.</p>

        <h5>9. Breach Clause</h5>
        <p>We may issue refunds for service delivery issues due to our fault; refund amount won't exceed the paid amount.</p>

        <h5>10. Legal Validity</h5>
        <p>Governing laws of Taiwan ROC; dispute resolution through the Taiwan ROC International Arbitration Center.</p>



      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {
    this.goRefundPolicy()
  },
  methods: {
   goRefundPolicy(){
     // 獲取URL中問號後面的參數
     // const params = new URLSearchParams(window.location.search);
     // const id = params.get('id');
     const { id } = this.$route.query
     if (id) {
       // 使用DOM操作滾動到對應位置
       const element = document.getElementById(id);
       if (element) {
         setTimeout(() => {
           element.scrollIntoView({});
         }, 100)
       }
     }
   }
  },
  watch:{
    '$route.query.id'(newId, oldId) {
      // 在這裡你可以進行相應的操作，比如滾動到對應位置
      const element = document.getElementById(newId);
      if (element) {
        this.goRefundPolicy()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.terms-title{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 32px;line-height: 46px;color: #FFFFFF;text-align: center;margin-bottom: 35px;}
.terms-body{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;color: #FFFFFF;}
.terms-body h5{color: #00A2FD;margin-top: 20px;}
.terms-body h6{}
@media screen and (max-width: 800px) {
  .terms-title{margin-bottom: 15px;}
  .details{padding-bottom: 40px;}
}
</style>
