<template>
  <div class="closing main-content">
    <div class="crumbs">
      <ul>
        <li><a href="#" @click="$router.push('/')">Home</a></li>
        <li>Checkout</li>
      </ul>
    </div>
    <div class="main">
      <h2 class="cart-title">
        <div class="_ico"><img src="@/assets/image/cart/closing-title.png" alt="購物車"></div><span>Checkout</span>
      </h2>
      <div class="cart-box">
        <div class="row">
          <div class="col _left">
            <div class="order-box">
              <div class="_title">Your order</div>
              <div class="_info">Order number：{{ orderobj.order_number }}</div>
              <div class="_info-box">
                <div class="_title">Product：</div>
                <div class="_item" v-for="(item, i) in orderobj.plan_list" :key="i">
                  <!-- <div class="_box">

                  </div> -->
                  <div class="_box">
                    <div class="_txt">{{ item.name }}</div>
                    <div class="_txt">{{ item.spec }} x {{ item.num }}</div>
                    <div class="_txt">USD $ {{ item.special_amount }}</div>
                  </div>

                </div>
                <div class="_code">
                  <div class="_txt">Use the discount code</div>
                  <el-input placeholder="Please enter the discount code" v-model="orderobj.promo_code" class="input-code">
                    <button slot="append" type="button" class="btn btn-default" @click="getcode">Use</button>
                  </el-input>
                </div>
                <div class="_total">
                  <div class="_box">
                    <div class="_txt">Subtotal</div>
                    <div class="_txt">USD $ {{ orderobj.discount_amount }}</div>
                  </div>
                  <div class="_box">
                    <div class="_txt">Discount</div>
                    <div class="_txt">-USD $ {{ discount }}</div>
                  </div>
                  <div class="_box">
                    <div class="_txt">Total</div>
                    <div class="_txt total">USD $ <span class="_total">{{ orderobj.discount_amountTwo }}</span></div>
                  </div>
                </div>
                <div class="_message">
                  <el-input type="textarea" :rows="4" placeholder="Message" v-model="orderobj.remark">
                  </el-input>

                </div>
              </div>
            </div>
          </div>
          <div class="col _right">
            <div class="closing-box">

              <div class="fp-tabs">
                <div class="_item _label">Please fill your information</div>
              </div>
              <div class="fp-body">
                <div class="fp-item">
                  <div class="_item"><el-input v-model="fristName" placeholder="Name" :disabled="isshow"></el-input></div>
                  <div class="_item"><el-input v-model="phone" placeholder="Phone( opitional)"></el-input>
                  </div>
                </div>
                <div class="fp-item">
                  <div class="_item" style="margin-bottom: 10px;"><el-input v-model="email" :disabled="isshow" type="email"
                                                                            placeholder="Your email"></el-input></div>
                  <div class="_item" style="margin-bottom: 10px;"><el-input v-model="reEmail" :disabled="isshow" type="email"
                                                                            placeholder="Re-enter email"></el-input></div>
                </div>
                <span style="margin-bottom: 25px;display: inline-block;margin-left: 8px;font-size: 14px;
        line-height: 20px;color: #5CE9C7;">Your email will recieve the esim you are going to buy </span>

                <div class="select-country">
                  <div class="_select">
                    <div class="_selectImg"><img :src="countryImg" alt=""></div>
                    <el-select v-model="countryIndex" placeholder="請選擇" style="max-width: 90px;" @change="showSelectedImage(countryIndex)">
                      <el-option
                          v-for="item in country"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        <span style="float: left"><img :src="item.label" alt=""></span>
                        <span style="float: right; font-size: 13px">+{{ item.code }}</span>
                      </el-option>
                    </el-select>
                  </div>
                  <div class="_input"><el-input v-model="phoneNumber" placeholder="Phone number" :disabled="isshow"></el-input></div>
                </div>

                <div class="item-title">
                  <div class="_label">Credit card Information</div>
                  <img src="../assets/image/cart/card-type.png" />
                </div>
                <div class="form-item">
                  <div class="tpfield" id="card-number"></div>
                  <el-input prefix-icon="ico-cart" class="icon-custom" disabled	 v-model="value" placeholder=""></el-input>
                </div>
                <div class="row form-item col-2">
                  <div class="col _item">
                    <div class="_label">Expiration Date</div>
                    <div class="btItem">
                      <div class="btItem-nox" id="card-expiration-date"></div>
                      <el-input v-model="value" disabled placeholder=""></el-input>
                    </div>
                  </div>
                  <div class="col _item">
                    <div class="_label">CVV</div>
                    <div class="btItem">
                      <div class="btItem-nox" id="card-ccv"></div>
                      <el-input v-model="value" disabled placeholder=""></el-input>
                    </div>
                  </div>
                </div>

              </div>
              <div class="fukuan">
                <div class="form-item">
                  <div class="row ">
                    <div class="col form-item-btn">
                      <button type="button" class="btn btn-primary btn-block" @click="goTO">Confirm Payment</button>
                    </div>
                  </div>
                </div>
                <div class="form-item">
                  <div class="row">
                    <div class="col form-item-txt text-center">
                      Fully certified for PCIDSS Level 1 security across all environments.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!--      <div>-->
    <!--        <label>CardView</label>-->
    <!--        <div id="cardview-container"></div>-->
    <!--      </div>-->
    <div class="ui grid centered doubling stackable">
      <div class="six wide column">
        <div class="ui segment">
          <h1 class="ui header">Direct Pay - iframe</h1>

          <form class="ui form">
            <div class="field">
              <label>信用卡</label>
              <!--                <div id="tappay-iframe"></div>-->
            </div>
          </form>

          <br>
          <div class="ui button" id="submit">Get Prime</div>
          <br>
          <pre class="ui error message" id="message" style="overflow-x: auto">
                </pre>
          <pre class="ui info message" id="result" style="overflow-x: auto">
                </pre>
          <pre class="ui info message" id="curl" style="overflow-x: auto">
                </pre>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import '../utils/tappay.js'
import {codeCheck, orderDetails, toPay, updatePreOrder} from "../request/api";

export default {
  name: 'Closing',
  data() {
    return {
      num: 1,
      value: '',
      code: 'TX8767',
      phoneNumber:'',
      countryImg: require('@/assets/image/ico-usa.png'),
      countryIndex:1,
      country:[
        {
          value:1,
          label: require('@/assets/image/ico-usa.png')
        },
        {
          value:2,
          label: require('@/assets/image/ico-usa.png')
        }

      ],
      receipt: 1,
      question: false,
      form: {},
      options: [
        {
          value: 1,
          label: '選項1'
        },
        {
          value: 2,
          label: '選項2'
        }
      ],
      tagList: ['# 美國', '# 本地卡', '# 按日計價'],

      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '使用方法'
        },
      ],
      orderobj: {},
      discount: 0,
      fristName: '',
      email: '',
      phone: '',
      reEmail: '',
      isshow:false
    }
  },
  created() {
    this.getOrderDetails()
  },

  mounted() {
    TPDirect.setupSDK(132722, 'app_iFBlPo5HWqhrwy4zpPgOEHa0Vkh3Ips1ktUyTZ9fVgvJkQ40hL4jEb27eiNJ', 'sandbox');
    var statusTable = {
      '0': '欄位已填好，並且沒有問題',
      '1': '欄位還沒有填寫',
      '2': '欄位有錯誤，此時在 CardView 裡面會用顯示 errorColor',
      '3': '使用者正在輸入中',
    }
    let fields = {
      number: {
        // css selector
        // element: '#card-number',
        element: document.getElementById('card-number'),
        placeholder: 'Please enter card number'
      },
      expirationDate: {
        // DOM object
        element: document.getElementById('card-expiration-date'),
        placeholder: 'MM/YY'
      },
      ccv: {
        element: '#card-ccv',
        placeholder: 'ccv'
      }
    }
    var config = {
      isUsedCcv: false,
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    }
    // TPDirect.card.setup(fields, defaultCardViewStyle, config)

    TPDirect.card.setup({
      fields: fields,
      styles: {
        // Style all elements
        'input': {
          'color': '#ffffff'
        },
        // Styling ccv field
        'input.ccv': {
          // 'font-size': '16px'
        },
        // Styling expiration-date field
        'input.expiration-date': {
          // 'font-size': '16px'
        },
        // Styling card-number field
        'input.card-number': {
          // 'font-size': '16px'
        },
        // style focus state
        ':focus': {
          // 'color': 'black'
        },
        // style valid state
        '.valid': {
          'color': 'green'
        },
        // style invalid state
        '.invalid': {
          'color': 'red'
        },
        // Media queries
        // Note that these apply to the iframe, not the root window.
        '@media screen and (max-width: 400px)': {
          'input': {
            'color': 'orange'
          }
        }
      },
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: true,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11
      }
    })
    TPDirect.card.onUpdate(function (update) {

      var submitButton = document.querySelector('#submit')
      var cardViewContainer = document.querySelector('#tappay-iframe')

      if (update.canGetPrime) {
        submitButton.removeAttribute('disabled')
      } else {
        submitButton.setAttribute('disabled', true)
      }

      var message = document.querySelector('#message')

      message.innerHTML = `
                canGetPrime: ${update.canGetPrime} \n
                cardNumberStatus: ${statusTable[update.status.number]} \n
                cardExpiryStatus: ${statusTable[update.status.expiry]} \n
                ccvStatus: ${statusTable[update.status.ccv]}
            `.replace(/    /g, '')

      if (update.hasError) {
        message.classList.add('error')
        message.classList.remove('info')
      } else {
        message.classList.remove('error')
        message.classList.add('info')
      }
    })
    document.querySelector('#submit').addEventListener('click', function(event) {
      TPDirect.card.getPrime(function(result) {
        if (result.status !== 0) {
          console.error('getPrime error')
        }
        var prime = result.card.prime
        console.log('getPrime success: ' + prime)
      })
    })
  },

  methods: {
    showSelectedImage(id){
      console.log(id)
      const img = this.country.filter(v => {
        if(v.value === id){
          return v.label
        }

      })
      this.countryImg = img[0].label
    },
    // 遞減
    less() {
      if (this.num == 1) return
      this.num -= 1
    },
    // 遞加
    add() {
      this.num += 1
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },
    //去付款
    async goTO() {
      // this.$router.push({
      //   path: '/paymentResult',
      //   query: {}
      // })
      if (this.fristName&& this.email && this.reEmail || this.phone) {
        if (this.email == this.reEmail) {
          let obj = {
            order_id: this.$route.query.id,
            promo_code: this.orderobj.promo_code,
            name: this.fristName,
            email: this.email,
            phone: this.phone,
            remark: this.orderobj.remark,
          }
          let res = await updatePreOrder(obj);
          if (res.code == 3) {
            window.sessionStorage.removeItem('shopCart')
            this.$store.commit('changgetCart')
            this.getLink()
          } else {
            this.$message.warning(res.msg)
          }
        } else {
          this.$message.warning('The two mailboxes are inconsistent')
        }
      } else if (
          !this.fristName
      ) {
        this.$message.warning('Please enter your name')
      } else if (
          !this.reEmail
      ) {
        this.$message.warning('Please enter the confirmation mailbox')
      }


    },

    //獲取付款頁面
    async getLink() {
      let data = {
        order_id: this.$route.query.id
      }
      let res = await toPay(data);
      if (res.code == 200) {
        window.location = res.data.pay_url
      } else {
        this.$message.warning(res.msg)
      }
    },


    //獲取訂單詳情
    async getOrderDetails() {
      let data = {
        order_id: this.$route.query.id
      }
      let res = await orderDetails(data);
      if (res.code == 1) {
        this.orderobj = res.data;
        this.orderobj.discount_amountTwo = res.data.discount_amount;
        if (res.data.promo_code) {
          this.getcode()
        }
        if(window.localStorage.getItem('TOKEN_NAME')){
          this.isshow = true
        }else{
          this.isshow = false
        }
        this.fristName = res.data.user_name
        this.email = res.data.user_email
        this.phone = res.data.user_phone,
            this.reEmail = res.data.user_email
      } else {
        this.$message.warning(res.msg)
      }
    },

    //使用折扣碼
    async getcode() {
      if (this.orderobj.promo_code) {
        let data = {
          promo_code: this.orderobj.promo_code
        }
        let res = await codeCheck(data)
        if (res.code == 1) {
          this.orderobj.discount_amountTwo = this.orderobj.discount_amountTwo + this.discount;
          this.discount = 0;
          let price = Number(this.orderobj.discount_amountTwo) * (Number(res.data.discount) / 100)
          this.discount = Math.round(Number(this.discount) + Number(price));
          this.orderobj.discount_amountTwo = Number(this.orderobj.discount_amountTwo) - Number(price);
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } else {
        this.discount = 0;
        this.orderobj.discount_amountTwo = this.orderobj.discount_amount;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.closing .cart-box ._left {
  max-width: 500px;
}

.closing-box {
  width: 100%;
}

._code {
  display: flex;
  align-items: center;

  ._txt {
    flex: 1;
  }

  .input-code {
    flex: 1;
  }
}

.closing-box {
  position: relative;
  height: 550px;
}

.fukuan {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

._message {
  display: flex;
  white-space: nowrap;
}

.el-textarea {
  // margin-left: 10px;
}
</style>
