<template>
  <div class="cart main-content">
    <div class="crumbs">
      <ul>
        <li><a @click="$router.push('/')">Home</a></li>
        <li>Shopping Cart</li>
      </ul>
    </div>
    <div class="main">
      <h2 class="cart-title">
        <div class="_ico"><img src="@/assets/image/cart/cart-title.png" alt="Shopping Cart"></div><span>Shopping Cart<span
            class="_card-number">{{ shopList.length }}item(s) of merchandise</span></span>
      </h2>
      <div class="cart-box">
        <div class="cart-herd">
          <div class="row">
            <div class="col _delete"></div>
            <div class="col _title">Product name</div>
            <div class="col _price">Price</div>
            <div class="col _number">Quantity</div>
            <div class="col _subtotal">Subtotal</div>
          </div>
        </div>
        <div class="cart-items">
          <div class="cart-item row" v-for="(item, i) in shopList" :key="i">
            <div class="col _delete">
              <div class="_ico" @click="removeCart(item)"><span>Remove</span></div>
            </div>
            <div class="col _title">
              <div class="_img">
                <img :src="item.image" alt="">
              </div>
              <div class="_body">
                <div class="_name">{{ item.name }}</div>
                <div class="_label">{{ item.days + ' Days'}} {{ item.spec }}<small style="display: inline-block" v-if="item.package && item.package.includes('By Day')">/ Day</small></div>
              </div>
            </div>
            <div class="col _price">USD <span>${{ item.special_amount.toLocaleString() }}</span></div>
            <div class="col _number">
              <div class="counter">
                <span @click="less(item)">-</span>
                <div>{{ item.num }}</div>
                <span @click="add(item)">+</span>
              </div>
            </div>
            <div class="col _subtotal">USD <span>${{ (Number(item.special_amount) * Number(item.num)).toLocaleString() }}</span></div>
          </div>
        </div>
        <div class="cart-footer">
          <div class="row">
            <div class="col _left">
              <!-- <div class="_code">
                <div class="_label">使用折扣碼</div>
                <el-input placeholder="請輸入折扣碼" v-model="code" class="input-code">
                  <button slot="append" type="button" class="btn btn-default" @click="getcode">使用</button>
                </el-input>
              </div> -->
              <div class="_right">
<!--                <div class="_item"><span class="_label">Subtotal</span><span class="subtotal">USD $ {{ xj }}</span></div>-->
<!--                <div class="_item"><span class="_label">Discount amount</span><span class="price">USD ${{ yh }}</span></div>-->
                <div class="_item"><span class="_label">Total</span><span class="total">USD <span class="_total">${{ zj.toLocaleString('en-US',{minimumFractionDigits:1,maximumFractionDigits:1})
                }}</span></span></div>
              </div>
              <!-- <div class="_message">
                <textarea rows="4" placeholder="留言" v-model="remark"></textarea>
              </div> -->
              <button type="button" class="btn btn-primary btn-block" @click="toClosing">Proceed to checkout</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Cart, placeOrder, deledtCart, carNum, codeCheck, getallCart } from '@/request/api'
export default {
  name: 'Cart',
  data() {
    return {
      num: 1,
      value: '',
      code: '',
      shopList: [],
      options: [
        {
          value: 1,
          label: '選項1'
        },
        {
          value: 2,
          label: '選項2'
        }
      ],
      tagList: ['# 美國', '# 本地卡', '# 按日計價'],

      // tab切換
      tabActive: 1,
      tabList: [
        {
          type: 1,
          title: '方案詳情'
        },
        {
          type: 2,
          title: '技術規格'
        },
        {
          type: 3,
          title: '使用方法'
        },
      ],
      xj: 0,
      zj: 0,
      yh: 0,
      remark: ''
    }
  },
  created() {
    this.getCart()
  },
  methods: {
    send_to_page() {
      // console.log(this.orderobj.plan_list)
      let items =  this.shopList.map(v =>{
            return {
              item_id:v.plan_id,
              item_name:v.name,
              item_category:v.days+'Days',
              item_category2:v.spec,
              item_category3:v.package,
              quantity:v.num,
              price: v.special_amount
            }
          })
      let event_config = {
        currency: "USD",
        value: (this.zj).toFixed(1),
        items: items
      }
      console.log(event_config)
      this.$gtag.event('view_cart', event_config);
    },
    // 遞減
    async less(item) {
      if (item.num > 1) {
        if (window.localStorage.getItem('TOKEN_NAME')) {
          let data = {
            type: 'decrease',
            plan_id: item.plan_id
          }
          let res = await carNum(data);
          this.getCart()
          this.$store.commit('changgetCart')
        } else {
          let cart = JSON.parse(window.sessionStorage.getItem('shopCart'));

          cart.find(iten => iten.price_id == item.price_id).num -= 1;
          window.sessionStorage.setItem('shopCart', JSON.stringify(cart));
          this.getCart();
          this.$store.commit('changgetCart')
        }
      }
    },
    // 遞加
    async add(item) {
      if (window.localStorage.getItem('TOKEN_NAME')) {
        let data = {
          type: 'add',
          plan_id: item.plan_id
        }
        let res = await carNum(data);
        this.getCart()
        this.$store.commit('changgetCart')
      } else {
        // console.log('----------------------------------')
        let cart = JSON.parse(window.sessionStorage.getItem('shopCart'));
        cart.find(iten => iten.price_id == item.price_id).num += 1;
        window.sessionStorage.setItem('shopCart', JSON.stringify(cart));
        this.getCart();
        this.$store.commit('changgetCart')
      }
    },
    // tab切換
    tabChange(type) {
      this.tabActive = type
    },
    // 加入我喜欢
    toLoves() {

    },
    // 分享
    toShare() {

    },
    async toClosing() {
      let list = []
      this.shopList.forEach(item => {
        let obj = {
          id: item.plan_id,
          price_id: item.price_id,
          num: item.num
        }
        list.push(obj)
      })
      let data = {
        plan: list,
        promo_code: this.code,
        remark: this.remark
      }
      let res = await placeOrder(data);
      if (res.code == 3) {
        this.$store.commit('changgetCart')
        this.$router.push({
          path: '/closing',
          query: {
            id: res.data.order_id
          }
        })
      } else {
        this.$message({
          type: 'warning',
          message: res.msg
        })
      }

    },

    //獲取購物車信息
    async getCart() {
      if (window.localStorage.getItem('TOKEN_NAME')) {
        if (JSON.parse(window.sessionStorage.getItem('shopCart'))) {
          let da = {
            cart_list: JSON.parse(window.sessionStorage.getItem('shopCart'))
          }
          let rep = await getallCart(da);
          if(rep.code == 1){
          this.$store.commit('changgetCart');
          window.sessionStorage.removeItem('shopCart')
          let res = await Cart();
          this.shopList = res.data
          this.xj = 0;
          this.zj = 0;
          res.data.forEach(item => {
            this.xj += Number(item.special_amount) * Number(item.num);
            this.zj = Number(this.xj) - Number(this.yh);
          });
          }else{
            this.$message.warning(rep.msg)
          }

        } else {
          let res = await Cart();
          this.shopList = res.data
          this.xj = 0;
          this.zj = 0;
          res.data.forEach(item => {
            this.xj += Number(item.special_amount) * Number(item.num);
            this.zj = Number(this.xj) - Number(this.yh);
          });
        }

      } else {
        this.shopList = JSON.parse(window.sessionStorage.getItem('shopCart'));
        this.xj = 0;
        this.zj = 0;
        JSON.parse(window.sessionStorage.getItem('shopCart')).forEach(item => {
          this.xj += Number(item.special_amount) * Number(item.num);
          this.zj = Number(this.xj) - Number(this.yh);
        });
      }
      console.log('================')
      this.send_to_page()
      // console.log('items',items)
    },

    //刪除購物車
    async removeCart(item) {
      let event_config = {
        currency: "USD",
        value: item.special_amount,
        items: [{
          item_id:item.plan_id,
          item_name:item.name,
          item_category:item.days+'Days',
          item_category2:item.spec,
          item_category3:item.package,
          quantity:item.num,
          price: item.special_amount
        }]
      }
      console.log(event_config)
      this.$gtag.event('remove_form_cart', event_config);

      if (window.localStorage.getItem('TOKEN_NAME')) {
        let data = {
          plan_id: item.plan_id,
          price_id: item.price_id
        }
        let res = await deledtCart(data)
        if (res.code == 1) {
          this.getCart();
          this.$store.commit('changgetCart')
        }
      } else {
        let cart = JSON.parse(window.sessionStorage.getItem('shopCart'));
        cart.splice(cart[cart.findIndex(iten => iten == item)], 1);
        window.sessionStorage.setItem('shopCart', JSON.stringify(cart));
        this.getCart();
        this.$store.commit('changgetCart')
      }
    },

    //使用優惠碼
    async getcode() {
      if (this.code) {
        let data = {
          promo_code: this.code
        }
        let res = await codeCheck(data)
        if (res.code == 1) {
          this.zj = this.zj + this.yh;
          this.yh = 0;
          this.yh = Math.round(Number(this.xj) * (Number(res.data.discount) / 100));
          this.zj = Number(this.xj) - Number(this.yh);
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } else {
        this.yh = 0;
        this.zj = this.xj;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.cart-footer ._right {
  position: relative;
  margin-bottom: 50px;
}
@media screen and (max-width: 800px){
  .cart-title{margin-bottom: 15px;}
  .cart-box ._title ._img{flex-shrink: 0;}
  .cart-footer .btn.btn-block{font-size: 16px;}
}
</style>
<style lang="less" ></style>
