<template>
  <div class="details">
    <div class="main-content">
      <div class="terms-title">
        Privacy Policy
      </div>
      <div class="terms-body">
        <p>eSImart is committed to upholding international standards of personal data privacy protection, exceeding them whenever possible, and complying with the requirements of the Personal Data (Privacy) Ordinance in Taiwan. We ensure that our staff strictly adheres to security and confidentiality standards. </p>
        <h5>1. Reasons for Data Collection</h5>
        <p></p>
        <p>We collect personal data and information to provide and enhance our services. This includes but is not limited to your name, address, contact details, identity card number, and payment information. We collect information for the following purposes:</p>
        <p>· Subscribing to services and support, including updates and notices.</p>
        <p>· Tailoring services for user needs and improving user experience.</p>
        <p>· System maintenance and troubleshooting.</p>
        <p>· Marketing and promotional purposes.</p>

        <h5>2. Disclosure of Information</h5>
        <p>You acknowledge that the information you provide may be accessed, disclosed, used, or transferred to various entities, including third-party service providers, financial institutions, debt collection agencies, and others as required by law or regulatory requirements.</p>

        <h5>3. User Preferences / Opt-Out</h5>
        <p>We offer the option to opt-out of unsolicited communications from eSImart. If you wish to stop receiving such communications, contact our customer relations, and we will remove you from our contact list.</p>

        <h5>4. Collection of Information</h5>
        <p>Using 'Cookies' We may use cookies to collect information on website usage. Users can disable cookies without affecting navigation, but it may affect certain features.</p>

          <h5>5. Spamming</h5>
        <p>We have a strict policy against spam emails and encourage users to report such incidents. Appropriate action will be taken if spam originates from eSImart accounts.</p>

            <h5>6. Security</h5>
        <p>We have security measures in place to safeguard collected data and protect against unauthorized access.</p>

         <h5>7. Period of Retention</h5>
        <p>We retain personal information for the necessary duration to fulfill the specified purpose.</p>

        <h5>8. Updates to Privacy Policy</h5>
        <p>We update this policy to reflect changes in services and the operating environment.</p>

        <h5>9. Other Rights</h5>
        <p>You retain rights under the Personal Data (Privacy) Ordinance of Taiwan, including access, correction, and data disallowance.</p>

        <h5>10. Guarantees and Limits of Liability</h5>
        <p>User responsibility for website use; eSImart assumes no responsibility for consequences arising from product, service, or information use.</p>

        <h5>11. Contact Us</h5>
        <p>For requests related to data access, correction, or inquiries, please contact us at service@esimart.com.</p>

        <h5>12. Copyright Notice & Disclaimer</h5>
        <p>12.1 Copyright Notice</p>
        <p>Trademarks, copyrights, and intellectual property rights belong to eSImart or other relevant parties. Use without consent is prohibited.</p>

        <h5>12.2 Disclaimer</h5>
        <p>Use of this website is the user's responsibility. eSImart assumes no responsibility for any consequences resulting from product, service, or information use obtained from this website or linked third-party websites.</p>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.terms-title{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 32px;line-height: 46px;color: #FFFFFF;text-align: center;margin-bottom: 35px;}
.terms-body{font-family: 'Noto Sans TC';font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;color: #FFFFFF;}
.terms-body h5{color: #00A2FD;margin-top: 20px;}
.terms-body h6{}
@media screen and (max-width: 800px) {
  .terms-title{margin-bottom: 15px;}
  .details{padding-bottom: 40px;}
}
</style>
