<template>
  <div class="details">
    <div class="main-content">
      <div class="support-title faq-title">
        FAQ
      </div>
      <div class="search-box">
        <el-input  v-model="keywords" placeholder="Search" class="input-with-select" @keyup.enter.native="seachEnter">
          <div slot="append" class="btn-img" @click="seachEnter"><img src="../../assets/image/search/search.png"/></div>
        </el-input>
      </div>
      <div class="faq-nav">
        <div v-for="(item,index) in qa_type" :key="index" :class="(item.id === type_id)?'active':''" class="item"><span @click="clickType(item.id)">{{ item.name }}</span></div>
      </div>
      <div class="support-body faq-body">
        <ul class="faq-list">
          <li class="faq-item js-accodionGroup" :class="item.show ? 'item-expand':'item-collapse'" v-for="(item,index) in qa_list" :key="index">
            <h3 class="-title js-accodionSwitch" @click="toggleQa(item)">{{ item.question }}</h3>
            <div class="js-accodionTarget" :class="item.show ? '':'style-hide'">
              <div class="-answer" v-html="item.answer"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {faqList , faqType} from "../../request/api";

export default {
  name: 'terms',
  data() {
    return {
      qa_list: [],
      qa_type:[],// 分類
      keywords:'',
      type_id:0,
    }
  },
  created() {
    this.getFqaType()
  },
  methods: {
    toggleQa(item) {
      item.show = !item.show
    },
    async getFqaType(){
      const res = await faqType({})
      if (res.code === 1) {
        this.qa_type = res.data
       this.type_id = this.qa_type[0].id
        console.log(this.qa_type)
       await  this.init()
      }
    },
    async init() {
      console.log('this.type_id',this.type_id)
      const res = await faqList({
        type_id:this.type_id,
        keywords:this.keywords,
        page: 1,
        limit: 10000
      })
      if (res.code === 1) {
        this.qa_list = res.data.list.map(v => {
          return {
            ...v,
            show: false
          }
        })
      }
    },
  seachEnter(){
      this.type_id = 0
   this.init()
  },
    clickType(id){
      this.keywords = ''
      this.type_id = id
      this.init()
    }
  }
}
</script>

<style lang="less" scoped>
.support-title{
  text-align: center;
  margin-bottom: 9px;
}
.support-body{
  padding-left: 0;
}
.faq-nav{ display: flex;margin: 0 -15px 42px;flex-wrap: wrap;}
.faq-nav .item{
  padding: 20px 15px 0;
}
.faq-nav .item span{
  padding: 7px 15px;
  border-radius: 20px;
  color: #FFF;
  font-family: Noto Sans TC;
  //font-size: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  cursor: pointer;
}
.faq-nav .item.active span{
  background: #0B57C9;
}
@media screen and (max-width: 800px){
  .faq-nav{
    display: block;
  }
  .faq-nav .item span {
    font-size: 16px;;
    font-weight: 400;
  }
  .faq-nav .item.active span{
    background: transparent;
    color: #00A2FD;
  }
}

</style>
